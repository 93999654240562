.button-flat-desktop {
  height: 50px;
  width: 100%;
  line-height: 24px;
  color: #333333;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  background-color: #FFFFFF;
}
.button-flat-desktop:focus {
  outline: 2px solid #DAAA00;
  border: 2px solid #DAAA00;
  outline-offset: 1px;
}
.button-flat-desktop:hover {
  background-color: transparent;
  text-decoration: underline;
}
.button-flat-desktop:focus-visible {
  outline: 2px solid #DAAA00;
  outline-offset: 1px;
}

.button-flat-desktop_one {
  padding: 8px 24px;
}

.button-flat-desktop_both {
  padding: 8px 0;
}

.button-flat__text {
  font-size: 14px;
}

.button-flat-mobile {
  padding: 8px 24px;
  width: 100%;
  line-height: 24px;
  color: #333333;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  height: 40px;
}
.button-flat-mobile:focus-visible {
  outline: #DAAA00 2px solid;
  outline-offset: 1px;
}

.servicio-asociado__separator {
  margin-top: 16px;
  margin-bottom: 16px;
  height: 2px;
  width: 100%;
  background-color: #DDDDDD;
}
@media screen and (min-width: 1024px) {
  .servicio-asociado__separator {
    margin-top: 24px;
    margin-bottom: 24px;
    background-color: #EEEEEE;
  }
}
.servicio-asociado__logo {
  display: inline-block;
  vertical-align: top;
}
.servicio-asociado__logo img {
  height: 32px;
  width: 32px;
}
@media screen and (min-width: 1024px) {
  .servicio-asociado__logo img {
    height: 64px;
    width: 64px;
  }
}
.servicio-asociado__left {
  display: inline-block;
  padding-left: 16px;
  width: 86%;
  font-size: 12px;
  line-height: 16px;
}
@media screen and (min-width: 1024px) {
  .servicio-asociado__left {
    padding-left: 32px;
    width: 386px;
    font-size: 16px;
    line-height: 28px;
  }
}
.servicio-asociado__left__title {
  font-weight: bold;
  margin-bottom: 8px;
}
@media screen and (min-width: 1024px) {
  .servicio-asociado__left__title {
    margin-bottom: 0px;
  }
}
.servicio-asociado__left__dateTime {
  display: inline-block;
  margin-top: 8px;
  color: #666666;
  font-size: 12px;
  line-height: 16px;
}
@media screen and (min-width: 1024px) {
  .servicio-asociado__left__dateTime {
    font-size: 14px;
    line-height: 24px;
  }
}
.servicio-asociado__right {
  display: inline-block;
  padding-top: 12px;
  margin-left: 48px;
  max-width: 50%;
}
@media screen and (min-width: 1024px) {
  .servicio-asociado__right {
    float: right;
    padding-top: 0px;
    padding-left: 0px;
    width: auto;
  }
}

.feedback__subtitle {
  margin-bottom: 32px;
}
@media screen and (min-width: 768px) {
  .feedback__subtitle {
    margin-bottom: 48px;
  }
}

.feedback-form__section {
  margin-top: 24px;
}
@media screen and (min-width: 768px) {
  .feedback-form__section--left {
    display: inline-block;
    width: 35%;
    text-align: right;
    vertical-align: top;
    padding-right: 16px;
    margin-top: 8px;
  }
}
@media screen and (min-width: 768px) {
  .feedback-form__section--right {
    display: inline-block;
    width: 65%;
  }
}
.feedback-form__section .render-field-nuevo--expanded {
  display: flex;
}
.feedback-form__section .render-field-nuevo--expanded textarea, .feedback-form__section .render-field-nuevo--expanded input {
  margin: 0px;
}
.feedback-form__section .render-field-nuevo--expanded .render-field-nuevo__input-container {
  border-width: 0px;
}
.feedback-form__section .render-field-nuevo--expanded .render-field-nuevo__input-container textarea {
  font-size: 14px;
  resize: none;
  min-height: 128px;
}
@media screen and (min-width: 768px) {
  .feedback-form__section .render-field-nuevo--expanded .render-field-nuevo__input-container textarea {
    font-size: 16px;
  }
}
.feedback-form__section .render-field-nuevo--expanded .render-field-nuevo__label-container {
  margin-bottom: 8px;
}
.feedback-form__section .render-field-nuevo--expanded .render-field-nuevo__label-container__label {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #212221;
}
@media screen and (min-width: 768px) {
  .feedback-form__section .render-field-nuevo--expanded .render-field-nuevo__label-container__label {
    font-size: 16px;
  }
}
.feedback-form__section .render-field-nuevo--expanded .render-field-nuevo__helper-text-container {
  display: none;
}
.feedback-form__error-msg {
  height: 20px;
  text-align: right;
  color: #B60000;
  font-size: 12px;
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  .feedback-form__error-msg {
    font-size: 14px;
    line-height: 20px;
  }
}
.feedback-form__captcha-container {
  height: 102px;
}
@media screen and (min-width: 768px) {
  .feedback-form__captcha-container {
    height: 106px;
  }
}
@media screen and (min-width: 1024px) {
  .feedback-form__captcha-container {
    margin-left: 35%;
  }
}

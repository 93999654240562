.consent__divider {
  background-color: #DDDDDD;
  height: 1px;
  border: 0;
  margin-bottom: 16px;
}
.consent__intro, .consent__intro a {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 32px;
  margin: 24px 0px;
}
.consent__list:first-child .consent__divider {
  display: none;
}
.consent__scope-title {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: #333333;
  margin: 6px 0px;
}
.consent__scope-description {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin: 6px 0px 16px;
}
.consent__terms-title {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  color: #333333;
  margin: 6px 0px;
}
.consent__terms-description {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
  margin: 6px 0px 16px;
}

.box {
  margin: 0 0 10px;
  padding: 10px;
  border: 0;
}
.box h2 {
  margin-bottom: 6px;
  padding: 0 4px;
  font-size: 20px;
}
@media screen and (min-width: 1024px) {
  .box h2 {
    margin-bottom: 16px;
    margin-top: 16px;
  }
}
.box__divider {
  background-color: #DDDDDD;
  height: 1px;
  border: 0;
  margin-bottom: 24px;
}
@media screen and (min-width: 1024px) {
  .box {
    border: 1px solid #EEEEEE;
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 54px;
    padding-top: 32px;
  }
  .box__divider {
    display: none;
  }
}

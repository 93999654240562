.overlay--visible {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
@media screen and (min-width: 1024px) {
  .overlay--visible {
    display: none;
  }
}
.overlay--hidden {
  display: none;
}

.focus-style:focus {
  outline: #DAAA00 auto 2px !important;
  outline-offset: 1px;
}

header {
  background-color: #FFFFFF;
  width: 100%;
  height: 47px;
  box-sizing: border-box;
  border-bottom: 1px solid #EEEEEE;
  position: relative;
  z-index: 6;
  font-size: 16px;
  line-height: 24px;
}
@media screen and (min-width: 1024px) {
  header {
    height: 55px;
  }
}
header .header-wrapper {
  padding: 8px 12px;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 1024px) {
  header .header-wrapper {
    height: 55px;
    padding: 12px;
    margin-right: auto;
  }
}
header .header-wrapper__left {
  display: flex;
  align-items: center;
  height: 100%;
  float: left;
  margin-left: 10px;
  font-weight: 700;
  color: #003DA5;
}
header .header-wrapper__left__logo {
  height: auto;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 7px;
  font-size: 18px;
}
@media screen and (min-width: 1024px) {
  header .header-wrapper__left__logo {
    font-size: 22px;
  }
}
header .header-wrapper__left__pipe {
  margin-bottom: 26px;
  font-size: 18px;
}
@media screen and (min-width: 1024px) {
  header .header-wrapper__left__pipe {
    margin-bottom: 29px;
    font-size: 22px;
  }
}
header .header-wrapper__left__texto {
  margin-bottom: 22px;
  font-size: 18px;
}
@media screen and (min-width: 1024px) {
  header .header-wrapper__left__texto {
    margin-bottom: 26px;
    font-size: 22px;
  }
}
header .header-wrapper__right {
  height: 100%;
  float: right;
}
header .header-internal-opt-in {
  float: right;
  position: inherit;
}
@media screen and (min-width: 1024px) {
  header .header-internal-opt-in {
    position: relative;
  }
}
header .header-external-opt-out {
  display: inline-block;
  vertical-align: middle;
}
header .header-nav {
  background-color: #FFFFFF;
  position: absolute;
  top: 47px;
  left: 0;
  width: 100%;
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
  header .header-nav {
    background-color: transparent;
    position: relative;
    top: 17px;
    left: auto;
    width: auto;
    float: right;
    box-shadow: none;
    border-radius: 3px;
    border: 1px solid #DDDDDD;
  }
}
header .header-nav__item {
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  height: 40px;
  width: 100%;
  line-height: 24px;
  border-bottom: 2px solid #EEEEEE;
  color: #000000;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  border-radius: 3px;
}
header .header-nav__item_level {
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  height: 40px;
  width: 100%;
  line-height: 24px;
  border-bottom: 2px solid #EEEEEE;
  color: #333333;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  border-radius: 3px;
}
header .header-nav__tooltip {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 43px;
  right: 0;
  background-color: #FFFFFF;
  border-radius: 3px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #BBBBBB;
  padding: 16px;
}
header .header-nav__tooltip__text {
  color: black;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  display: block;
  text-align: center;
  width: 100%;
}
header .header-nav__tooltip__text:hover {
  text-decoration: underline;
}

.header-buttons {
  display: flex;
}

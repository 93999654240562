.main_container {
  display: flex;
  flex-direction: row;
  align-self: center;
}

.step_vector_container {
  display: flex;
  flex-direction: row;
}

.vector_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
@media screen and (min-width: 768px) {
  .vector_container {
    width: 100px;
  }
}
@media screen and (min-width: 1024px) {
  .vector_container {
    width: 150px;
  }
}

.vector {
  width: 30px;
  height: 0px;
  border: 1px solid #E2E9F1;
  align-self: center;
}
@media screen and (min-width: 768px) {
  .vector {
    width: 160px;
  }
}
@media screen and (min-width: 1024px) {
  .vector {
    width: 200px;
  }
}

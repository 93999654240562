.no-padding {
  padding: 0;
}

.p-l-0 {
  padding-left: 0px;
}

.p-r-0 {
  padding-right: 0px;
}

.m-t-0 {
  margin-top: 0px;
}

.m-t-1 {
  margin-top: 16px;
}

.m-b-16px {
  margin-bottom: 16px;
}

.m-l-16px {
  margin-left: 16px;
}

.block {
  display: block;
}

.max-height {
  height: 100% !important;
}

.text-center {
  text-align: center;
}

input,
textarea,
select {
  display: block;
  width: 100%;
  margin: 4px 0 0 0;
  height: 42px;
  min-width: 120px;
  padding: 8px;
  line-height: 24px;
  background-color: white;
  border: 1px solid #BBBBBB;
  border-radius: 2px;
  outline: none;
}
input:hover,
textarea:hover,
select:hover {
  border: 1px solid #666666;
}
input:focus,
textarea:focus,
select:focus {
  outline: 2px solid #DAAA00;
  outline-offset: 1px;
}
input:disabled,
textarea:disabled,
select:disabled {
  border: 1px dotted #BBBBBB;
}
@media screen and (min-width: 1024px) {
  input,
  textarea,
  select {
    margin: 8px 0 0 0;
    line-height: 28px;
  }
}

input[type=checkbox],
input[type=radio] {
  vertical-align: middle;
}

html {
  height: 100%;
}

#root {
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

.main-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: #F9F9F9;
}

.page-container {
  flex: 1;
}

body {
  margin: 0 !important;
  padding: 0;
  min-height: 100%;
  position: relative;
  font-family: "Open Sans", sans-serif;
  background: #F9F9F9;
  color: #222222;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: 24px;
}
@media screen and (min-width: 768px) {
  body {
    font-size: 16px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1024px) {
  body {
    font-size: 16px;
    line-height: 28px;
  }
}
body .alt {
  font-size: 14px;
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  body .alt {
    font-size: 16px;
    line-height: 24px;
  }
}

h1 {
  margin: 1rem 0;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
}
@media screen and (min-width: 768px) {
  h1 {
    font-size: 40px;
    line-height: 56px;
  }
}
@media screen and (min-width: 1024px) {
  h1 {
    font-size: 40px;
    line-height: 56px;
  }
}

h2 {
  margin: 0;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
  line-height: 28px;
}
@media screen and (min-width: 768px) {
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
}
@media screen and (min-width: 1024px) {
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
}

h3 {
  margin: 16px 0;
  font-weight: 600 !important;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 18px;
}
@media screen and (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1024px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  margin: 16px 0;
  font-weight: 600 !important;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  line-height: 16px;
}
@media screen and (min-width: 768px) {
  h4 {
    font-size: 16px;
    line-height: 18px;
  }
}
@media screen and (min-width: 1024px) {
  h4 {
    font-size: 16px;
    line-height: 18px;
  }
}

.headline {
  font-size: 16px;
  line-height: 28px;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
@media screen and (min-width: 768px) {
  .headline {
    font-size: 21px;
    line-height: 32px;
  }
}
@media screen and (min-width: 1024px) {
  .headline {
    font-size: 21px;
    line-height: 32px;
  }
}

.subheading {
  margin: 8px 0px 0px;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 28px;
}
@media screen and (min-width: 768px) {
  .subheading {
    font-size: 18px;
    line-height: 32px;
    margin: 20px 0px 0px;
  }
}
@media screen and (min-width: 1024px) {
  .subheading {
    font-size: 18px;
    line-height: 32px;
    margin: 20px 0px 0px;
  }
}

.caption, .password-field-nuevo__popup__tips__link {
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  .caption, .password-field-nuevo__popup__tips__link {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .caption, .password-field-nuevo__popup__tips__link {
    font-size: 14px;
    line-height: 20px;
  }
}

a {
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: #003DA5;
}
a:focus {
  text-decoration: underline;
  outline: 2px solid #DAAA00;
  outline-offset: 1px;
}
a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .hidden-mobile {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .hidden-tablet {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none;
  }
}

button {
  padding: 0;
  border: 0;
  background: 0;
}

.render-field-nuevo {
  display: block;
  width: 100%;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .render-field-nuevo--expanded {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 1024px) {
  .render-field-nuevo--expanded {
    min-height: 74px;
  }
  .render-field-nuevo--expanded .render-field-nuevo__label-container {
    display: inline-block;
    width: 35%;
    text-align: right;
    padding-right: 16px;
  }
  .render-field-nuevo--expanded .render-field-nuevo__helper-text-container {
    margin-left: 35%;
  }
}
.render-field-nuevo__label-container {
  display: flex;
  justify-content: space-between;
}
.render-field-nuevo__label-container__label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #042F62;
}
.render-field-nuevo__label-container__link {
  line-height: 24px;
  text-decoration-line: underline;
  color: #003DA5;
}
@media screen and (min-width: 768px) {
  .render-field-nuevo__label-container__link {
    line-height: 28px;
  }
}
@media screen and (min-width: 1024px) {
  .render-field-nuevo__label-container__link {
    line-height: 28px;
  }
}
.render-field-nuevo__label-container__link_container {
  display: flex;
  justify-content: flex-start;
}
@media screen and (min-width: 768px) {
  .render-field-nuevo__label-container--text-field {
    margin-top: 8px;
    vertical-align: top;
  }
}
.render-field-nuevo__help-text, .render-field-nuevo__help-text__warn, .render-field-nuevo__help-text__error, .render-field-nuevo__help-text__info {
  height: 20px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  .render-field-nuevo__help-text, .render-field-nuevo__help-text__warn, .render-field-nuevo__help-text__error, .render-field-nuevo__help-text__info {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .render-field-nuevo__help-text, .render-field-nuevo__help-text__warn, .render-field-nuevo__help-text__error, .render-field-nuevo__help-text__info {
    font-size: 14px;
    line-height: 20px;
  }
}
.render-field-nuevo__help-text__info {
  color: #333333;
}
.render-field-nuevo__help-text__error {
  color: #B60000;
}
.render-field-nuevo__help-text__warn {
  color: #FCC526;
}
.render-field-nuevo__input--error {
  border: 2px solid #B60000;
}
.render-field-nuevo__input--error:focus {
  outline: 0;
}
.render-field-nuevo__helper-text-container {
  min-height: 24px;
  margin: 10px 0px 0px 5px;
  font-size: 16px;
  line-height: 20px;
}
@media screen and (min-width: 1024px) {
  .render-field-nuevo__helper-text-container {
    margin-bottom: auto;
  }
}

.inputContainer input, .inputContainer select {
  border: 1px solid #042F62;
  border-radius: 5px;
  height: 56px;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.password-field-nuevo__text-ok {
  color: #279E3F;
}
.password-field-nuevo__text-warning {
  color: #fcce26;
}
.password-field-nuevo__text-error {
  color: #B60000;
}
.password-field-nuevo__bar-base, .password-field-nuevo__bar-error, .password-field-nuevo__bar-ok, .password-field-nuevo__bar-warning {
  height: 8px;
  width: 100%;
  border-radius: 3px;
  background-color: #EEEEEE;
  margin-bottom: 8px;
}
.password-field-nuevo__bar-error, .password-field-nuevo__bar-ok, .password-field-nuevo__bar-warning {
  background-color: #B60000;
  width: 0%;
  -webkit-transition: width 1s; /* Safari */
  transition: width 1s;
}
.password-field-nuevo__bar-warning {
  background-color: #fcce26;
}
.password-field-nuevo__bar-ok {
  background-color: #279E3F;
}
.password-field-nuevo__popup {
  width: 100%;
  padding: 16px 0;
  z-index: 9;
}
@media screen and (min-width: 1024px) {
  .password-field-nuevo__popup {
    max-width: 276px;
    position: absolute;
    border: 1px solid #BBBBBB;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    padding: 16px;
    z-index: 9;
    left: calc(100% + 12px);
    top: -42px;
  }
  .password-field-nuevo__popup ::before, .password-field-nuevo__popup ::after {
    right: 100%;
    top: 20px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .password-field-nuevo__popup ::before {
    border-color: rgba(187, 187, 187, 0);
    border-right-color: #BBBBBB;
    border-width: 12px;
    margin-top: -12px;
  }
  .password-field-nuevo__popup ::after {
    border-color: transparent;
    border-right-color: #FFFFFF;
    border-width: 10px;
    margin-top: -10px;
  }
}
.password-field-nuevo__popup__title {
  margin: 0 0 8px 0;
}
.password-field-nuevo__popup__tips {
  color: #666666;
}
.password-field-nuevo__popup__tips__link {
  background-color: #FFFFFF;
  border: 0;
  color: #003DA5;
  margin: 0 0 8px 0;
  padding: 0;
}

.passwordWidget {
  position: relative;
}

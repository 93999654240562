@media screen and (max-width: 699px) and (min-width: 520px) {
  .wrapper {
    min-width: 420px;
  }
  .rectangle {
    min-width: 420px;
  }
}
.seguridad__alert {
  margin: 16px auto 0;
  max-width: 960px;
  width: 90%;
}
@media screen and (min-width: 768px) {
  .seguridad__alert {
    margin: 32px auto 0;
    width: 75%;
  }
}
@media screen and (min-width: 1024px) {
  .seguridad__alert {
    margin: 32px auto 0;
    width: 960px;
  }
}

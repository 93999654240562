.card {
  margin: 16px auto 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.18);
  background-color: #FFFFFF;
  max-width: 960px;
  width: 90%;
  border-radius: 3px;
}
@media screen and (min-width: 768px) {
  .card {
    margin: 32px auto 0;
    width: 75%;
  }
}
@media screen and (min-width: 1024px) {
  .card {
    margin: 32px auto 0;
    width: 960px;
  }
}
.card__registro {
  width: 460px;
  background-color: #F9F9F9;
  border: none;
  box-shadow: none;
}
@media screen and (max-width: 768px) {
  .card__registro {
    width: 346px;
    min-height: 1280px;
  }
}
.card__inner {
  width: 100%;
  padding: 24px 16px 34px 16px;
  display: table;
  margin: 0;
}
.card__inner__registro {
  box-sizing: border-box;
  padding: 24px 50px 24px 50px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(104, 114, 134, 0.6);
  background-color: #FFFFFF;
}
@media screen and (max-width: 768px) {
  .card__inner__registro {
    padding: 8px 9px 8px 9px;
  }
}
@media screen and (min-width: 768px) {
  .card__inner {
    padding: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .card__inner {
    padding: 40px 40px 48px;
  }
}
.card__mid {
  max-width: 640px;
  width: 90%;
}

.login-option-tab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 16px;
  margin: 12px;
  width: 480px;
  height: 85px;
  background: #FFFFFF;
  box-shadow: 0px 6px 12px rgba(104, 114, 134, 0.3);
  border-radius: 5px;
}
.login-option-tab:focus {
  outline-color: #DAAA00 !important;
}
@media screen and (max-width: 768px) {
  .login-option-tab {
    width: 100%;
    margin: 8px 0px;
    height: 100px;
    padding: 20px 10px;
  }
}
.login-option-tab__info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.login-option-tab__info__logo {
  width: 45px;
  height: 45px;
  margin-right: 8px;
}
.login-option-tab__info__texto {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.login-option-tab__info__texto__nombre {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  color: #073184;
}
.login-option-tab__info__texto__descripcion {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #4A5568;
  text-align: left;
}
.login-option-tab__nivel {
  display: flex;
  flex-direction: row;
}
.login-option-tab__nivel__texto {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #4A5568;
}
.login-option-tab__nivel__logo {
  margin-left: 10px;
}
@media screen and (max-width: 768px) {
  .login-option-tab__nivel__logo {
    padding: 0px;
  }
}

.form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 30px 50px 20px;
  gap: 24px;
  width: 328px;
  background: #FFFFFF;
  border-radius: 8px;
}
@media screen and (min-width: 768px) {
  .form_container {
    width: 460px;
  }
}
@media screen and (min-width: 1024px) {
  .form_container {
    width: 700px;
  }
}

.form_p {
  width: 325px;
  height: 44px;
  margin-bottom: 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #4A5568;
}
@media screen and (min-width: 768px) {
  .form_p {
    width: 510px;
  }
}

.button_style {
  width: 200px;
}

.input_label_container, .input_label_container_numero_telefono_help_text {
  display: flex;
  flex-direction: column;
  height: 95px;
}
.input_label_container_numero_telefono_help_text {
  height: 156px;
}
.input_label_container .form_label, .input_label_container_numero_telefono_help_text .form_label, .input_label_container .form_error_label, .input_label_container_numero_telefono_help_text .form_error_label {
  width: 328px;
  height: 22px;
  margin-bottom: 4px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
@media screen and (min-width: 768px) {
  .input_label_container .form_label, .input_label_container_numero_telefono_help_text .form_label, .input_label_container .form_error_label, .input_label_container_numero_telefono_help_text .form_error_label {
    width: 360px;
  }
}
.input_label_container .form_label, .input_label_container_numero_telefono_help_text .form_label {
  color: #042F62;
}
.input_label_container .form_error_label, .input_label_container_numero_telefono_help_text .form_error_label {
  color: #B60000;
}
.input_label_container .form_input, .input_label_container_numero_telefono_help_text .form_input, .input_label_container .form_error_input, .input_label_container_numero_telefono_help_text .form_error_input {
  width: 328px;
  height: 56px;
  border-radius: 4px;
  padding: 17px 0 17px 16px;
  background: #FFFFFF;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #031435;
  text-transform: uppercase;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
}
.input_label_container .form_input:focus, .input_label_container_numero_telefono_help_text .form_input:focus, .input_label_container .form_error_input:focus, .input_label_container_numero_telefono_help_text .form_error_input:focus {
  background-color: #FFFFFF;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.2509803922);
}
@media screen and (min-width: 768px) {
  .input_label_container .form_input, .input_label_container_numero_telefono_help_text .form_input, .input_label_container .form_error_input, .input_label_container_numero_telefono_help_text .form_error_input {
    width: 360px;
  }
}
.input_label_container .form_input:disabled, .input_label_container_numero_telefono_help_text .form_input:disabled, .input_label_container .form_error_input:disabled, .input_label_container_numero_telefono_help_text .form_error_input:disabled {
  background: #E6E8EB;
  border: none;
  text-transform: none;
}
.input_label_container .form_input:disabled:hover, .input_label_container_numero_telefono_help_text .form_input:disabled:hover, .input_label_container .form_error_input:disabled:hover, .input_label_container_numero_telefono_help_text .form_error_input:disabled:hover {
  border: none;
}
.input_label_container .form_input::placeholder, .input_label_container_numero_telefono_help_text .form_input::placeholder, .input_label_container .form_error_input::placeholder, .input_label_container_numero_telefono_help_text .form_error_input::placeholder {
  width: 77px;
  height: 22px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #687286;
}
.input_label_container .form_input, .input_label_container_numero_telefono_help_text .form_input {
  border-color: #042F62;
}
.input_label_container .form_error_input, .input_label_container_numero_telefono_help_text .form_error_input {
  border: #B60000 solid !important;
}

.error_message {
  width: 325px;
  height: 42px;
  margin-top: 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #B60000;
}

.numero_serie_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  grid-gap: 7px;
  width: 328px;
  height: 270px;
  line-height: 16px;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .numero_serie_container {
    width: 500px;
    margin-bottom: 0;
    height: 313px;
    grid-gap: 20px;
  }
}

.imagen_numero_serie {
  width: 270px;
  height: 180px;
}

.texto_informativo_container {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  margin-left: 49px;
  margin-bottom: -20px;
}
@media screen and (min-width: 768px) {
  .texto_informativo_container {
    margin-left: 135px;
  }
}

.contenedor_texto_vector {
  display: flex;
  flex-direction: column;
  width: 132px;
  align-items: center;
}

.vector {
  width: 31px;
  border-left: 2.5px solid #EB5757;
  border-top: 2.5px solid #EB5757;
}

.vector_recto {
  width: 31px;
  height: 31px;
  border-left: 3px solid #EB5757;
}

.texto_informativo {
  width: 112px;
  height: 15px;
  margin: -7px 0px 5px 5px;
  position: relative;
  align-self: flex-start;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}

.span_checkbox {
  font-size: 16px;
  line-height: 20px;
  color: #4A5568;
}

.textoContinuar, .textoError {
  width: 328px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4A5568;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .textoContinuar, .textoError {
    width: 500px;
    margin-top: 0;
  }
}

.textoError {
  margin-top: 0;
}

.contenedorInputsError {
  display: flex;
  flex-direction: column;
  height: 150px;
}

.contenedorInputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contenedorNumeroTelefono input, .contenedorNumeroTelefonoDisabled input {
  width: 328px !important;
  height: 56px;
  border-radius: 4px;
  padding: 17px 0 17px 16px;
  border-color: #042F62 !important;
  background: #FFFFFF;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #031435;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .contenedorNumeroTelefono input, .contenedorNumeroTelefonoDisabled input {
    width: 360px !important;
  }
}
.contenedorNumeroTelefono input:disabled, .contenedorNumeroTelefonoDisabled input:disabled {
  background: #E6E8EB;
  border: none;
  text-transform: none;
}
.contenedorNumeroTelefono input:disabled:hover, .contenedorNumeroTelefonoDisabled input:disabled:hover {
  border: none;
}
.contenedorNumeroTelefonoDisabled {
  cursor: default;
}

.help_text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  color: #000000;
  font-size: 15px;
  line-height: 22px;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 4px;
}

.titulo {
  margin-top: 28px;
  align-self: center;
}

.contenedorPrincipal {
  display: flex;
  flex-direction: column;
}

.contenedorNombre {
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: center;
  background-color: #003DA5;
  color: #FFFFFF;
  padding: 12px;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.contenedorDatos {
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.contenedorDatosMovil {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.textoIcono {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.contenedorDato {
  display: flex;
  flex-direction: column;
  flex: 4;
}

.campo, .campoValorPais, .campoValor {
  font-family: "Open Sans";
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.campoValor {
  font-weight: 400;
  margin-top: 4px;
  margin-left: 33px;
}
.campoValorPais {
  font-weight: 400;
  margin-top: 4px;
  margin-left: 8px;
}

.linkSeguridad {
  padding: 12px;
  align-self: center;
}

.bandera {
  margin-top: 4px;
  margin-left: 33px;
  width: 24px;
  height: 24px;
}

.contenedorBanderaPais {
  display: flex;
  flex-direction: row;
}

.center {
  align-self: center;
}

.volver {
  margin: 22px 22px 32px 22px;
}
.volver__boton {
  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #003DA5;
  align-items: center;
  cursor: pointer !important;
}
.volver__boton__icono {
  margin-right: 14px;
}
.volver__boton__texto {
  margin-bottom: 1px;
}
.volver__boton:focus {
  outline: #DAAA00 solid 2px;
}

.overlay--visible {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
@media screen and (min-width: 1024px) {
  .overlay--visible {
    display: none;
  }
}
.overlay--hidden {
  display: none;
}

header {
  background-color: #FFFFFF;
  width: 100%;
  height: 47px;
  box-sizing: border-box;
  border-bottom: 1px solid #EEEEEE;
  position: relative;
  z-index: 6;
  font-size: 16px;
  line-height: 24px;
}
@media screen and (min-width: 1024px) {
  header {
    height: 55px;
  }
}
header .headerWrapper {
  padding: 8px 12px;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 1024px) {
  header .headerWrapper {
    height: 55px;
    padding: 12px;
    margin-right: auto;
  }
}
header .headerWrapperIzquierda {
  display: flex;
  align-items: center;
  height: 100%;
  float: left;
  margin-left: 10px;
  font-weight: 700;
  color: #003DA5;
}
header .headerWrapperIzquierdaLogo {
  height: auto;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 7px;
  font-size: 18px;
}
@media screen and (min-width: 1024px) {
  header .headerWrapperIzquierdaLogo {
    font-size: 22px;
  }
}
header .headerWrapperIzquierdaGuion {
  margin-bottom: 26px;
  font-size: 18px;
}
@media screen and (min-width: 1024px) {
  header .headerWrapperIzquierdaGuion {
    margin-bottom: 29px;
    font-size: 22px;
  }
}
header .headerWrapperIzquierdaTexto {
  margin-bottom: 22px;
  font-size: 18px;
}
@media screen and (min-width: 1024px) {
  header .headerWrapperIzquierdaTexto {
    margin-bottom: 26px;
    font-size: 22px;
  }
}
header .headerWrapperDerecha {
  height: 100%;
  float: right;
}

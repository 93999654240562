.titulo {
  font-weight: 600;
  font-size: 32px;
  line-height: 47px;
  text-align: center;
  position: relative;
}

.contenedor {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 24px 16px 40px;
  margin: 0px;
}
@media screen and (min-width: 1024px) {
  .contenedor {
    padding: 70px 48px 70px;
  }
}

.main_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: start;
  width: 80px;
}
@media screen and (min-width: 768px) {
  .main_container {
    width: 120px;
  }
}
@media screen and (min-width: 1024px) {
  .main_container {
    width: 150px;
  }
}
.main_container:hover {
  cursor: pointer;
}

.box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #E2E9F1;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #687286;
}

.box_is_actual_step {
  background: #E2E9F1;
  color: #020F28;
  border: 1px solid #003DA5;
}

.p_actual_step {
  color: #020F28;
  margin: 8px 0px 0px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}
@media screen and (min-width: 768px) {
  .p_actual_step {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
}

.p_inactive_step {
  width: 100%;
  margin: 8px 0px 0px 0px;
  color: #687286;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .p_inactive_step {
    font-size: 16px;
    line-height: 22px;
  }
}

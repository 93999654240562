.button-section, .button-section--vertical {
  display: table;
  margin: 16px auto 0;
}
@media screen and (min-width: 768px) {
  .button-section, .button-section--vertical {
    margin: 16px 0 0 auto;
  }
}
@media screen and (min-width: 1024px) {
  .button-section, .button-section--vertical {
    margin: 16px 0 0 auto;
  }
}
.button-section--vertical {
  display: flex;
  flex-direction: column-reverse;
}
.button-section--vertical .buttonSolid {
  width: 100%;
}
.button-section__secondary {
  margin-right: 16px;
}
.button-section__secondary__vertical {
  padding-top: 20px;
}
.button-section__second {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .button-section__second {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 328px;
    margin-left: 22px;
    margin-top: 16px;
    height: 104px;
  }
}

.second-class--primary {
  background-color: #042F62;
  color: white;
  padding: 10px 25px 10px 25px;
  border-radius: 5px;
  width: 328px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}

.second-class--secondary {
  margin-right: 10px;
  border: #042F62 1px solid;
  border-radius: 5px;
  color: #042F62;
  width: 328px;
  background-color: #F9F9F9;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .second-class--secondary {
    margin-right: 0px;
  }
}

.alert {
  background-color: #F3F3F3;
  border-left: 3px solid #BBBBBB;
  padding: 16px;
  width: 100%;
  margin: 18px 12px 0 0;
  font-size: 14px;
  line-height: 24px;
}
.alert__icon {
  vertical-align: top;
  margin-right: 16px;
  float: left;
}
.alert__icon img {
  vertical-align: baseline;
}
.alert__icon__text {
  margin-left: 38px;
}
.alert-close-button {
  float: right;
}
.alert__action {
  display: inline-block;
  color: #003DA5;
  text-decoration: underline;
  cursor: pointer;
}
.alert--warning {
  background-color: #FFF6DC;
  border-color: #FCC526;
  color: #333333;
}
.alert--success {
  background-color: #E8FFEC;
  border-color: #279E3F;
  color: #052d0d;
}
.alert--error {
  border-color: #EB5757;
  background-color: rgba(235, 87, 87, 0.2196078431);
  font-size: 13px;
  color: #4A5568;
  font-weight: 600;
  border-radius: 5px;
  border-width: 7px;
  padding: 10px 16px 10px 16px;
}
.alert--error ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
@media screen and (max-width: 768px) {
  .alert--error {
    margin-bottom: 28px;
  }
}
.alert--info {
  border-color: #003DA5;
  background-color: #E9F2FF;
  font-size: 13px;
  color: #4A5568;
  font-weight: 600;
  padding: 10px 16px 10px 16px;
  text-align: left;
  border-radius: 5px;
  border-width: 7px;
  margin-top: 0px;
}

.clearfix {
  clear: both;
}

.render-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 16px 0 0 0;
}
@media screen and (min-width: 1024px) {
  .render-field--expanded {
    min-height: 74px;
  }
  .render-field--expanded .render-field__label-container {
    display: inline-block;
    width: 35%;
    text-align: right;
    padding-right: 16px;
  }
  .render-field--expanded .render-field__input-container {
    display: inline-block;
    width: 400px;
  }
  .render-field--expanded .render-field__helper-text-container {
    margin-left: 35%;
  }
}
.render-field__label-container__link {
  float: right;
  cursor: pointer;
}
.render-field__help-text, .render-field__help-text__warn, .render-field__help-text__error, .render-field__help-text__info {
  height: 20px;
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  .render-field__help-text, .render-field__help-text__warn, .render-field__help-text__error, .render-field__help-text__info {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .render-field__help-text, .render-field__help-text__warn, .render-field__help-text__error, .render-field__help-text__info {
    font-size: 14px;
    line-height: 20px;
  }
}
.render-field__help-text__info {
  color: #333333;
}
.render-field__help-text__error {
  color: #B60000;
}
.render-field__help-text__warn {
  color: #FCC526;
}
.render-field__input--error {
  border: 2px solid #B60000;
}
.render-field__input--error:focus {
  outline: 0;
}
.render-field__helper-text-container {
  min-height: 24px;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 20px;
}
@media screen and (min-width: 1024px) {
  .render-field__helper-text-container {
    margin-bottom: auto;
  }
}

.captcha {
  width: 328px;
  margin-bottom: 16px;
  margin-left: 16px;
}
.captcha div:first-child {
  outline-color: #DAAA00;
}
.captcha div:first-child div:first-child {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .captcha {
    margin: 0px;
  }
}

.checkbox-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.checkbox-field__label {
  display: flex;
  align-items: center;
  width: 291px;
}
.checkbox-field__checkbox {
  display: inline;
  height: 16px;
  width: 16px;
  min-width: 16px;
  margin: 0 8px 0 0;
  vertical-align: middle;
  text-align: center;
}
.checkbox-field__container {
  display: flex;
  flex-direction: row;
}
.checkbox-field__input {
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox-field__error {
  color: #B60000;
  margin-left: 22px;
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .checkbox-field__error {
    margin-left: 24px;
    font-size: 12px;
  }
}

.popup {
  position: absolute;
  width: 276px;
  max-width: 276px;
  border: 1px solid #BBBBBB;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  padding: 8px;
  font-size: 14px;
  line-height: 20px;
  z-index: 999;
  -webkit-animation: fadein 0.2s linear forwards;
  animation: fadein 0.2s linear forwards;
  opacity: 0;
  left: calc(75% + 8px);
  top: -42px;
}
.popup ::before, .popup ::after {
  right: 100%;
  top: 20px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.popup ::before {
  border-color: rgba(187, 187, 187, 0);
  border-right-color: #BBBBBB;
  border-width: 12px;
  margin-top: -12px;
}
.popup ::after {
  border-color: transparent;
  border-right-color: #ffffff;
  border-width: 10px;
  margin-top: -10px;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

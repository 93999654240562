.acciones {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  max-width: 100%;
  overflow-x: auto;
}
.acciones__tabla {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}
.acciones__tabla__striped tr:nth-child(2n) {
  background-color: #f8f8f8;
}
.acciones__tabla td, .acciones__tabla th {
  padding: 10px 16px;
  vertical-align: top;
  border: 1px solid #ddd;
}
.acciones__tabla th {
  font-weight: 700;
  line-height: 1.2;
  text-align: left;
  vertical-align: bottom;
  background-color: #eee;
}
.acciones__tabla tr {
  background-color: #fff;
  border-top: 1px solid #bbb;
}

@media screen and (max-width: 992px) {
  .acciones__tabla__responsive {
    width: 100%;
    max-width: 100%;
    display: block;
    overflow: auto;
  }
  .acciones__tabla__responsive thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .acciones__tabla__responsive tbody, .acciones__tabla__responsive td, .acciones__tabla__responsive th, .acciones__tabla__responsive thead, .acciones__tabla__responsive tr {
    display: block;
    overflow: auto;
  }
  .acciones__tabla__responsive td, .acciones__tabla__responsive tr {
    box-sizing: border-box;
    width: 100%;
    float: left;
  }
  .acciones__tabla__responsive tr {
    border: 1px solid #ccc;
    padding: 10px 0;
  }
  .acciones__tabla__responsive tr td {
    border: none !important;
    border-top: 1px solid #eee;
    position: relative;
    white-space: normal;
    text-align: left;
    font-weight: 700 !important;
  }
}
@media screen and (max-width: 992px) {
  .acciones__tabla__responsive td[data-title]:before {
    font-weight: 400;
    content: attr(data-title) " ";
    display: block;
  }
}

.ayuda a {
  font-size: 14px;
  line-height: 24px;
}
@media screen and (min-width: 768px) {
  .ayuda a {
    font-size: 16px;
    line-height: 28px;
  }
}
.ayuda__items-container {
  padding: 0 16px 24px 16px;
}
.ayuda__items-container h2 {
  margin-top: 32px;
}
.ayuda__items-container__link {
  margin-bottom: 16px;
  text-decoration: underline;
}
.ayuda__line {
  border-bottom: 2px solid #DDDDDD;
  box-sizing: border-box;
  width: 100%;
}
.ayuda__footer {
  display: flex;
  align-items: baseline;
  position: relative;
}
@media screen and (min-width: 1024px) {
  .ayuda__footer {
    line-height: 64px;
  }
}
.ayuda__footer__icon {
  height: 64px;
  width: 64px;
}
@media screen and (min-width: 1024px) {
  .ayuda__footer__icon {
    float: left;
  }
}
.ayuda__footer__text {
  margin: 0 !important;
}
@media screen and (min-width: 1024px) {
  .ayuda__footer__text {
    margin-left: 24px !important;
    float: left;
    line-height: 64px !important;
  }
}
.ayuda__footer__link {
  margin: 0 !important;
}
@media screen and (min-width: 1024px) {
  .ayuda__footer__link {
    margin-left: 8px !important;
    float: left;
    line-height: 64px !important;
  }
}

.margin-title {
  margin-bottom: 10px;
  font-size: 20px;
}

.calendario .react-calendar {
  width: 312px;
  background: #FFFFFF;
  border: 1px solid #EEF2F7;
  border-radius: 8px;
}
.calendario .react-calendar button {
  margin: 4px;
  flex: none !important;
}
.calendario .react-calendar__month-view__days {
  padding: 7px 0 7px 0;
}
.calendario .react-calendar__tile {
  width: 35px;
  height: 35px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 13px;
  color: #28A745;
  background-color: #ECF6E8;
  border: 1.5px solid #28A745 !important;
  border-radius: 4px;
}
.calendario .react-calendar__tile:disabled {
  background-color: #FFFFFF;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 13px;
  color: #9AA1AE;
  border: none !important;
}
.calendario .react-calendar__tile:hover {
  background: #EEF2F7;
  border-radius: 4px;
}
.calendario .react-calendar__tile--active {
  width: 35px;
  height: 35px;
  transition: 300ms ease-out;
  color: #003DA5;
  background-color: #E2E9F1 !important;
  border: 1.5px solid #003DA5 !important;
  border-radius: 4px;
}
.calendario .react-calendar__tile--now {
  background-color: #FFFFFF;
}
.calendario .react-calendar__month-view__weekdays {
  grid-gap: 10.7px;
  padding: 7px 0 7px 0;
}
.calendario .react-calendar__month-view__weekdays__weekday {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 13px;
  color: #9AA1AE;
  flex: none !important;
  margin: 0px -2.3px 0px 10px;
}
.calendario abbr[title] {
  text-decoration: none !important;
  border-bottom: none !important;
}
.calendario .react-calendar__navigation {
  margin-bottom: 0;
  justify-content: center;
}
.calendario .react-calendar__navigation button {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 13px;
  color: #042F62;
}
.calendario .react-calendar__navigation button:hover {
  background-color: #FFFFFF !important;
}
.calendario .react-calendar__navigation button:disabled {
  background-color: #FFFFFF !important;
  color: #FFFFFF;
}
.calendario .react-calendar__navigation button:focus {
  background-color: #FFFFFF !important;
}
.calendario .react-calendar__navigation__label {
  pointer-events: none;
}

.titulo {
  max-height: 44px;
  max-width: 780px;
  margin-top: 0;
  margin-bottom: 24px;
  color: #222222;
  font-family: "Open Sans", sans-serif;
  font-size: 32px;
  font-weight: bold;
  line-height: 44px;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
.titulo__registro {
  font-style: normal;
  font-size: 32px;
  font-weight: 600;
  line-height: 47px;
  color: #1A202C;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .titulo__registro {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    width: 328px;
    margin-top: 0px;
  }
}

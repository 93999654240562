.container {
  display: flex;
  flex-direction: column;
}

.titulo {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  width: 100%;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  gap: 48px;
  margin: 0px 0px 40px;
}

.paragraph {
  margin: 0;
}

.agregarCalendariosContenedor {
  display: flex;
  align-self: center;
  flex-direction: row;
  gap: 50px;
  margin-bottom: 40px;
}

.titulo {
  margin: 48px 0px 10px 0px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  font-size: 20px;
  line-height: 25px;
}
@media screen and (min-width: 768px) {
  .titulo {
    font-size: 24px;
    line-height: 33px;
  }
}

.eligeHora {
  margin-top: 26px;
}

.subtitulo {
  font-size: 16px;
  line-height: 22px;
  margin: 0px 0px 12px 0px;
}
@media screen and (min-width: 768px) {
  .subtitulo {
    margin: 0px 0px 12px 0px;
    font-size: 20px;
    line-height: 27px;
  }
}

.aclaracionHora {
  margin: 0px 0px 24px 0px;
}

.fechaHora {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  gap: 14px;
}
@media screen and (min-width: 768px) {
  .fechaHora {
    flex-direction: row;
    margin-bottom: 48px;
    gap: 48px;
  }
}

.small-card {
  margin: 16px auto 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.18);
  background-color: #FFFFFF;
  border-radius: 3px;
  width: 90%;
  max-width: 480px;
  padding: 24px 16px 32px;
}
@media screen and (min-width: 768px) {
  .small-card {
    padding: 36px 40px 48px 40px;
    margin: 32px auto 0;
  }
}

.error {
  max-width: 800px;
  margin: 0 auto;
  transform: translateY(50%);
}
.error__desc {
  text-align: center;
  margin: 0 auto;
}
@media screen and (min-width: 1024px) {
  .error__desc {
    height: 360px;
    text-align: left;
  }
}
@media screen and (min-width: 1024px) {
  .error__desc__content {
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    width: 100%;
  }
}
.error__desc__content__text {
  margin: 0 auto;
  max-width: 340px;
}
@media screen and (min-width: 1024px) {
  .error__desc__content__text {
    margin: 0;
  }
  .error__desc__content__text a {
    font-size: 16px;
  }
}
.error__desc__content__btn {
  margin: 24px 0;
  margin-right: 16px;
  max-width: 160px;
}
.error__desc__botones {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media screen and (min-width: 768px) {
  .error__desc__botones {
    margin: 0px auto;
    width: 60%;
  }
}
@media screen and (min-width: 1024px) {
  .error__desc__botones {
    margin: 0px;
    width: 80%;
  }
}
.error__desc__botones button {
  width: 100%;
}
.error__img {
  text-align: center;
}
.error__img img {
  width: 120px;
}
@media screen and (min-width: 1024px) {
  .error__img img {
    width: auto;
    height: 270px;
  }
}

.error_vertical {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0px 15px;
}
@media screen and (min-width: 1024px) {
  .error_vertical {
    width: 378px;
    padding: 0px;
  }
}
.error_vertical__desc {
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .error_vertical__desc {
    margin-top: 40px;
  }
}
.error_vertical__img {
  text-align: center;
}
.error_vertical__img img {
  width: 192px;
  margin-left: 50px;
}
@media screen and (min-width: 1024px) {
  .error_vertical__img img {
    min-width: 250px;
    margin-left: 90px;
  }
}

.error_abitab {
  display: flex;
  height: 100vh;
}
.error_abitab .contenido {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  margin: auto;
  text-align: center;
  gap: 22px;
}
@media screen and (min-width: 768px) {
  .error_abitab .contenido {
    width: 60%;
  }
}
@media screen and (min-width: 1024px) {
  .error_abitab .contenido {
    width: 40%;
  }
}
.error_abitab .contenido .titulo {
  font-size: 28px;
  line-height: 32px;
  margin: 0px;
}
@media screen and (min-width: 1024px) {
  .error_abitab .contenido .titulo {
    font-size: 34px;
    line-height: 40px;
  }
}
.error_abitab .contenido .descripcion {
  margin: 0px;
}
.error_abitab .contenido .botones {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.error_abitab .contenido .botones button {
  width: 100%;
}

@import "../../styles/helpers/variables.scss";
@import "../../styles/helpers/mixins.scss";

// ---------------------------------------------------------------------------
// RenderField
// ---------------------------------------------------------------------------
.render-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: $spacing-16 0 0 0;

  &--expanded {
    @include desktop {
      min-height: 74px;

      .render-field__label-container {
        display: inline-block;
        width: $render-field-expanded-label-width;
        text-align: right;
        padding-right: $spacing-16;
      }
      .render-field__input-container {
        display: inline-block;
        width: 400px;
      }
      .render-field__helper-text-container {
        margin-left: $render-field-expanded-label-width;
      }
    }
  }

  &__label-container {
    &__link {
      float: right;
      cursor: pointer;
    }
  }

  &__help-text {
    height: 20px;
    margin-bottom: $spacing-16;

    // Mobile
    font-size: 12px;
    line-height: 20px;

    // Tablet
    @include tablet {
      font-size: 14px;
      line-height: 20px;
    }

    // Desktop
    @include desktop {
      font-size: 14px;
      line-height: 20px;
    }

    &__info {
      @extend .render-field__help-text;
      color: $grey-2;
    }
    &__error {
      @extend .render-field__help-text;
      color: $error;
    }
    &__warn {
      @extend .render-field__help-text;
      color: $secondary-dark;
    }
  }

  &__input {
    &--error {
      border: 2px solid $error;
      &:focus {
        outline: 0;
      }
    }
  }


  &__helper-text-container {
    min-height: $spacing-24;
    margin-bottom: $spacing-16;
    font-size: 16px;
    line-height: 20px;

    @include desktop {
      margin-bottom: auto;
    }
  }
}

// ---------------------------------------------------------------------------
// Estilos nuevos
// ---------------------------------------------------------------------------
.captcha {
  width: 328px;
  margin-bottom: 16px;
  margin-left: 16px;
  
  div:first-child {
    outline-color: $gold;
    div:first-child {
      display: flex;
      justify-content: center;
    }
  }

  @include mobile {
    margin: 0px;
  }
}

// ---------------------------------------------------------------------------
// CheckboxField
// ---------------------------------------------------------------------------
.checkbox-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  &__label {
    display: flex;
    align-items: center;
    width: 291px;
  }
  
  &__checkbox {
    display: inline;
    height: $spacing-16;
    width: $spacing-16;
    min-width: $spacing-16;
    margin: 0 8px 0 0;
    vertical-align: middle;
    text-align: center;
  }

  &__container {
    display: flex;
    flex-direction: row;
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__error {
    color: $dark-red;
    margin-left: 22px;
    font-size: 14px;

    @include mobile {
      margin-left: 24px;
      font-size: 12px;
    }
  }
}

// ---------------------------------------------------------------------------
// Helper text popup
// ---------------------------------------------------------------------------
.popup {
	position: absolute;
	width: 276px;
	max-width: 276px;
	border: 1px solid $grey-4;
	border-radius: 4px;
	background-color: #FFFFFF;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
  padding: 8px;
	font-size: 14px;
	line-height: 20px;
	z-index: 999;
	-webkit-animation: fadein 0.2s linear forwards;
	animation: fadein 0.2s linear forwards;
	opacity: 0;
  left: calc(75% + 8px);
  top: -42px;

  ::before, ::after {
    right: 100%;
  	top: 20px;
  	border: solid transparent;
  	content: " ";
  	height: 0;
  	width: 0;
  	position: absolute;
  	pointer-events: none;
  }

  ::before {
    border-color: rgba(187, 187, 187, 0);
  	border-right-color: #BBBBBB;
  	border-width: 12px;
  	margin-top: -12px;
  }

  ::after {
  	border-color: transparent;
  	border-right-color: #ffffff;
  	border-width: 10px;
  	margin-top: -10px;
  }
}

@-webkit-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

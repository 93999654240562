.cambio_contrasena .render-field-nuevo__label-container__label {
  font-weight: 400;
  color: #222222;
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .cambio_contrasena .render-field-nuevo__label-container__label {
    font-size: 14px;
  }
}

.no-padding {
  padding: 0;
}

.p-l-0 {
  padding-left: 0px;
}

.p-r-0 {
  padding-right: 0px;
}

.m-t-0 {
  margin-top: 0px;
}

.m-t-1 {
  margin-top: 16px;
}

.m-b-16px {
  margin-bottom: 16px;
}

.m-l-16px {
  margin-left: 16px;
}

.block {
  display: block;
}

.max-height {
  height: 100% !important;
}

.text-center {
  text-align: center;
}

input,
textarea,
select {
  display: block;
  width: 100%;
  margin: 4px 0 0 0;
  height: 42px;
  min-width: 120px;
  padding: 8px;
  line-height: 24px;
  background-color: white;
  border: 1px solid #BBBBBB;
  border-radius: 2px;
  outline: none;
}
input:hover,
textarea:hover,
select:hover {
  border: 1px solid #666666;
}
input:focus,
textarea:focus,
select:focus {
  outline: 2px solid #DAAA00;
  outline-offset: 1px;
}
input:disabled,
textarea:disabled,
select:disabled {
  border: 1px dotted #BBBBBB;
}
@media screen and (min-width: 1024px) {
  input,
  textarea,
  select {
    margin: 8px 0 0 0;
    line-height: 28px;
  }
}

input[type=checkbox],
input[type=radio] {
  vertical-align: middle;
}

html {
  height: 100%;
}

#root {
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

.main-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: #F9F9F9;
}

.page-container {
  flex: 1;
}

body {
  margin: 0 !important;
  padding: 0;
  min-height: 100%;
  position: relative;
  font-family: "Open Sans", sans-serif;
  background: #F9F9F9;
  color: #222222;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: 24px;
}
@media screen and (min-width: 768px) {
  body {
    font-size: 16px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1024px) {
  body {
    font-size: 16px;
    line-height: 28px;
  }
}
body .alt {
  font-size: 14px;
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  body .alt {
    font-size: 16px;
    line-height: 24px;
  }
}

h1 {
  margin: 1rem 0;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
}
@media screen and (min-width: 768px) {
  h1 {
    font-size: 40px;
    line-height: 56px;
  }
}
@media screen and (min-width: 1024px) {
  h1 {
    font-size: 40px;
    line-height: 56px;
  }
}

h2 {
  margin: 0;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
  line-height: 28px;
}
@media screen and (min-width: 768px) {
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
}
@media screen and (min-width: 1024px) {
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
}

h3 {
  margin: 16px 0;
  font-weight: 600 !important;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 18px;
}
@media screen and (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1024px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  margin: 16px 0;
  font-weight: 600 !important;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  line-height: 16px;
}
@media screen and (min-width: 768px) {
  h4 {
    font-size: 16px;
    line-height: 18px;
  }
}
@media screen and (min-width: 1024px) {
  h4 {
    font-size: 16px;
    line-height: 18px;
  }
}

.headline, .markdown h1 + p {
  font-size: 16px;
  line-height: 28px;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
@media screen and (min-width: 768px) {
  .headline, .markdown h1 + p {
    font-size: 21px;
    line-height: 32px;
  }
}
@media screen and (min-width: 1024px) {
  .headline, .markdown h1 + p {
    font-size: 21px;
    line-height: 32px;
  }
}

.subheading {
  margin: 8px 0px 0px;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 28px;
}
@media screen and (min-width: 768px) {
  .subheading {
    font-size: 18px;
    line-height: 32px;
    margin: 20px 0px 0px;
  }
}
@media screen and (min-width: 1024px) {
  .subheading {
    font-size: 18px;
    line-height: 32px;
    margin: 20px 0px 0px;
  }
}

.caption {
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  .caption {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .caption {
    font-size: 14px;
    line-height: 20px;
  }
}

a {
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: #003DA5;
}
a:focus {
  text-decoration: underline;
  outline: 2px solid #DAAA00;
  outline-offset: 1px;
}
a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .hidden-mobile {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .hidden-tablet {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none;
  }
}

button {
  padding: 0;
  border: 0;
  background: 0;
}

.markdown h1 {
  margin: 0;
  line-height: 30px;
  margin-bottom: 8px;
}
@media screen and (min-width: 768px) {
  .markdown h1 {
    line-height: 44px;
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .markdown h1 {
    line-height: 44px;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 768px) {
  .markdown p {
    margin-bottom: 32px;
  }
}
@media screen and (min-width: 1024px) {
  .markdown p {
    margin-bottom: 32px;
  }
}
@media screen and (min-width: 1024px) {
  .markdown p a {
    font-size: 16px;
  }
}

.metodos_disponibles {
  margin: 20px 0 0;
}
.metodos_disponibles__contenedor {
  display: flex;
  flex-direction: column;
}
.metodos_disponibles .markdown {
  align-self: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  color: #000000;
}
.metodos_disponibles__titulo h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 47px;
  text-align: center;
  position: relative;
}
.metodos_disponibles table {
  width: calc(100% - 320px);
  margin: 0px 160px;
}
.metodos_disponibles table img {
  margin-right: 10px;
  margin-bottom: 3px;
}
.metodos_disponibles table thead {
  padding: 10px;
  gap: 10px;
  height: 76px;
  background: #EEF2F7;
}
.metodos_disponibles table thead th {
  border: 1px solid #E0E0E0;
  height: 76px;
}
.metodos_disponibles table td {
  padding: 18px;
  background: white;
  min-width: 160px;
  min-height: 116px;
  border: 1px solid #E0E0E0;
  text-align: left;
}
.metodos_disponibles table td:first-child {
  font-weight: 700;
  background: #F9FBFC;
}
.metodos_disponibles table td:first-child a {
  display: block;
  padding: 4px 8px;
  border: 1px solid #042F62;
  border-radius: 4px;
  color: #042F62;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  margin-top: 10px;
  font-weight: 400;
  width: fit-content;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}
.metodos_disponibles__boton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .metodos_disponibles__boton {
    margin-bottom: 20px;
  }
}
.metodos_disponibles__boton a {
  text-decoration-line: underline;
}
@media screen and (max-width: 992px) {
  .metodos_disponibles {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .metodos_disponibles .markdown {
    width: 100%;
    overflow: auto;
    margin-left: 16px;
  }
  .metodos_disponibles table {
    font-size: 12px;
    width: 100%;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
  }
  .metodos_disponibles table img {
    margin-right: 10px;
  }
  .metodos_disponibles table a {
    font-size: 12px;
  }
  .metodos_disponibles table th {
    padding: 20px;
    height: 76px;
    width: 120px;
    vertical-align: middle;
  }
  .metodos_disponibles table td {
    padding: 20px;
    height: 76px;
    width: 122px;
    vertical-align: middle;
  }
  .metodos_disponibles table thead {
    position: sticky;
    top: 0;
    z-index: 1;
    width: 25vw;
  }
  .metodos_disponibles table thead tr th:first-child {
    position: sticky;
    left: 0;
    padding: 20px;
    height: 76px;
    width: 115px;
    background-color: #EEF2F7;
  }
  .metodos_disponibles table tbody tr td:first-child {
    position: sticky;
    left: 0;
    padding: 20px;
    height: 76px;
    width: 115px;
  }
  .metodos_disponibles__titulo h1 {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    position: relative;
  }
}

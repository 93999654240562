.botonCalendario, .botonCalendario_outlook, .botonCalendario_apple, .botonCalendario_google {
  background-size: 50px 50px;
  min-width: auto;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  border: none;
}
.botonCalendario:hover, .botonCalendario_outlook:hover, .botonCalendario_apple:hover, .botonCalendario_google:hover {
  border: none;
}
.botonCalendario_google {
  background: url(../../assets/icons/googleCalendarIcon.svg);
}
.botonCalendario_apple {
  background: url(../../assets/icons/appleIcon.svg);
}
.botonCalendario_outlook {
  background: url(../../assets/icons/outlookIcon.svg);
}

@import "./variables.scss";

// ------------------------------------------------------------------------
//
// ------------------------------------------------------------------------
@mixin tablet {
  @media screen and (min-width: #{$tablet-min-width}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$desktop-min-width}) {
    @content;
  }
}

@mixin small-desktop {
  @media screen and (min-width: #{$small-desktop-min-width}) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: #{$tablet-min-width}){
    @content;
  }
}

// ------------------------------------------------------------------------
// Centering helpers
// ------------------------------------------------------------------------
@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin vt-centerer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin hl-centerer {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

@mixin vb-centerer {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
}

@mixin hr-centerer {
  position: relative;
  right: 50%;
  transform: translate(50%);
}

// ------------------------------------------------------------------------
// Font helpers
// ------------------------------------------------------------------------
@mixin antialiased {
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

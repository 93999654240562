.container {
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .container {
    padding-top: 48px;
  }
}
.container p {
  margin: 0;
}

.titulo {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .titulo {
    font-size: 24px;
    line-height: 33px;
  }
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  gap: 24px;
  margin: 32px 0px;
}
@media screen and (min-width: 768px) {
  .body {
    margin: 48px 0px;
    font-size: 20px;
    line-height: 24px;
  }
}

.datosAgenda {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 0px;
  background-color: #F9FBFC;
  border: 1px solid #003DA5;
  border-radius: 8px;
}
.datosAgenda .titulo {
  margin-bottom: 32px;
  font-size: 20px;
  line-height: 24px;
  color: #042F62;
}
.datosAgenda .bold {
  font-weight: 600;
  margin-left: 16px;
}
@media screen and (min-width: 768px) {
  .datosAgenda {
    width: 64%;
  }
}
@media screen and (min-width: 1024px) {
  .datosAgenda {
    width: 43%;
  }
}

.datosAgendaBody {
  display: flex;
  justify-content: center;
  gap: 16px;
}
.datosAgendaBody b {
  font-weight: 600;
}

.datosUsuarioMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  gap: 16px;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
}

.datosUsuario {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.paragraph {
  margin: 0;
}

.paragraphTitle {
  font-weight: 600;
}

.alinearDerecha {
  display: flex;
  flex-direction: column;
  text-align: end;
  gap: 16px;
}

.alinearIzquierda {
  display: flex;
  flex-direction: column;
  text-align: start;
  font-weight: 600;
  gap: 16px;
}

.aclaracion {
  margin-top: 2px;
  font-size: 14px;
  line-height: 21px;
  color: #020F28;
}

.error {
  font-size: 18px;
  color: #B60000;
}

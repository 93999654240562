.contenedorPrincipal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textoPrincipal {
  font-size: 40px;
  margin-top: 50px;
}

.contenedorBotones {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin: 60px 0;
  gap: 20px;
}

.boton {
  width: 370px;
}

.textoInfo {
  font-size: 20px;
}

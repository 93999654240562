@import "../../styles/helpers/variables.scss";
@import "../../styles/helpers/mixins.scss";

.texto {
    font-size: 90px;
}

.contenedor {
    display: flex;
    width: fit-content;
    margin: 0;
    min-height: 50px;
    padding: 8px 16px;
    border: 1px solid $grey-9;
    border-radius: 8px;
    width: 100%;

    @include tablet {
        width: 147px;
        max-height: 316px;
        padding: 16px 26px 16px 16px;
    }
}

.grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    gap: 12px;
    justify-content: center;

    > .button {
        border: 1px solid $grey-9;
    }
}

.fakeBar {
    height: auto;
    border: 4px solid $grey-9;
    border-radius: 8px;
}

.horasInternasDiv {
    display: flex;
    width: 100%;
    padding: 0px 10px 0px 0px;
    align-items: center;
    justify-content: center;
    overflow-y: auto;

    &.disabled {
        overflow-y: hidden;
    }

    scrollbar-width: thin;
    scrollbar-color: $grey-10 $grey-9;

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: $grey-9;
        border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background: $grey-10;
        border-radius: 8px;
    }
}

.ul {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;
}

.li {
    margin: 0;
    padding: 0;
}

.boton {
    margin: 0;
    padding: 9px 10px;
    box-sizing: border-box;
    line-height: 22px;
    height: 40px;
    border: 1px solid transparent;
    border-radius: 4px;
    white-space: nowrap;
    transition: $transitionDefault;

    &:disabled {
        color: #EEF2F7;
    }

    &:hover:enabled {
        background-color: #EEF2F7;
    }

    &.selected:enabled {
        background-color: #EEF2F7;
        border: 1px solid #003DA5;
    }
}

.contenedorSpinner {
    min-height: 100px;
    min-width: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

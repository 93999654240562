:export {
  blue: #003DA5;
}

.spinner .spin {
  display: table;
  margin: 0 auto;
}
.spinner .spin__text {
  color: #666666;
  margin-top: 40px;
}
.spinner .card__inner__spinner {
  min-height: 0;
  padding: 0;
}
.spinner__transparent, .spinner__transparent__videollamada {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}
.spinner__transparent {
  height: 300px;
  display: flex;
  align-items: center;
}
.spinner__transparent__videollamada .spinner__container, .spinner__container {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -40%);
}
.spinner__transparent__videollamada .spinner__container {
  top: auto;
}

.ball-spin-fade-loader > div {
  width: 10px;
  height: 10px;
}

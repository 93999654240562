.breadcrumbs {
  color: #666666;
  font-size: 18px;
  line-height: 26px;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
.breadcrumbs a {
  font-size: 18px;
  line-height: 26px;
}

.faq-detail__empty {
  color: #222222;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
.faq-detail__p {
  font-size: 14px;
}
.faq-detail--title {
  margin-top: 16px;
  margin-bottom: 24px;
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
.faq-detail__container {
  color: #222222;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
.faq-detail__container h2 {
  font-size: 26px;
  font-weight: bold;
  line-height: 32px;
}
.faq-detail__container h3 {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
}

.faqs-relacionadas__container {
  padding-right: 0px;
  padding-left: 28px;
}

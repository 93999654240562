.contenedorPrincipal {
  padding: 20px 20px 0px 20px;
}
@media screen and (min-width: 768px) {
  .contenedorPrincipal {
    padding: 0px 60px 0px 60px;
  }
}
.contenedorPrincipal p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
@media screen and (min-width: 768px) {
  .contenedorPrincipal p {
    font-size: 19px;
  }
}

.botonContenedor {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.botonContenedor_empezar {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.titulo {
  margin: 0px 0px 40px 0px;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  width: 100%;
}

.listaCondiciones {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0;
}

.condicion {
  font-size: 14px;
  line-height: 24px;
}
@media screen and (min-width: 768px) {
  .condicion {
    font-size: 19px;
  }
}
.condicion a {
  color: black !important;
  font-size: 20px !important;
  line-height: 24px !important;
  text-decoration: underline 0.5px black !important;
}
.condicion b {
  font-weight: 600;
}
.condicion p {
  margin: 0;
}

.checkbox {
  margin: 33px 0px 30px 25px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.letraChicha {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
}
@media screen and (min-width: 768px) {
  .letraChicha {
    font-size: 14px;
  }
}

.errorContenedor {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.errorTexto {
  width: 325px;
  height: 42px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #B60000;
}

.feedback_section {
  padding: 16px 0;
  background-color: #FFFFFF;
  border-top: 1px solid #EEEEEE;
  color: #222222;
  width: 100%;
  position: relative;
}
.feedback_section_wrapper {
  width: 80%;
  max-width: 1304px;
  margin: auto;
  padding: 0 16px;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .feedback_section_wrapper {
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (max-width: 768px) {
  .feedback_section_wrapper {
    margin: 0px;
    padding: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }
  .feedback_section_wrapper a {
    font-size: 12px;
  }
}
.feedback_section__item_container {
  display: block;
  align-items: baseline;
}
.feedback_section__item_container a {
  margin: 0px 8px;
}
@media screen and (min-width: 1024px) {
  .feedback_section__item_container {
    display: flex;
  }
  .feedback_section__item_container a {
    font-size: 16px;
    margin-right: 0;
  }
  .feedback_section__item_container a:first-child {
    margin-left: 32px;
  }
  .feedback_section__item_container a:last-child {
    margin-left: 16px;
  }
}
@media screen and (max-width: 768px) {
  .feedback_section__item_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
.feedback_section__item_container:nth-child(n+2) {
  margin-top: 16px;
}
@media screen and (min-width: 1024px) {
  .feedback_section__item_container:nth-child(n+2) {
    margin-top: 0;
    float: right;
  }
}
@media screen and (max-width: 768px) {
  .feedback_section__item_container:nth-child(n+2) {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.feedback_section__item {
  display: block;
  text-align: center;
}
.feedback_section__item a {
  line-height: 28px;
}
@media screen and (min-width: 1024px) {
  .feedback_section__item {
    display: flex;
  }
}
@media screen and (max-width: 768px) {
  .feedback_section__item {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }
}

.boton, .menuItem li {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  font-family: "Open Sans";
  line-height: 20px;
  text-align: right;
  color: #003DA5;
  vertical-align: middle;
}

.menuItem {
  margin-top: 32px !important;
}
@media screen and (min-width: 1024px) {
  .menuItem {
    margin-top: 40px !important;
  }
}
.menuItem li {
  width: 100vh;
  opacity: 1 !important;
  text-align: center !important;
}
@media screen and (min-width: 768px) {
  .menuItem li {
    width: auto;
  }
}

.headerInternoRoute {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #BBBBBB;
  margin-bottom: 8px;
  padding-bottom: 6px;
}

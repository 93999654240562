.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.botonContinuar {
  align-self: center;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .botonContinuar {
    width: fit-content;
  }
}

.botonPerfil {
  height: 45px;
  font-size: 16px;
  line-height: 22px;
  padding: 10px 25px;
  color: #042F62;
  border: 1px solid #042F62;
  border-radius: 4px;
  background: none;
}

:export {
  blue: #003DA5;
}

.button-option {
  padding: 16px 40px;
  width: 100%;
  border-top: 2px solid #EEEEEE;
  border-bottom: 2px solid #EEEEEE;
  text-align: left;
}
.button-option__text {
  display: inline-block;
  max-width: calc(100% - 24px);
  line-height: 20px;
  color: #333333;
}
@media screen and (min-width: 768px) {
  .button-option__text {
    line-height: 28px;
  }
}
.button-option__img {
  float: right;
  margin: 6px;
}
@media screen and (min-width: 768px) {
  .button-option__img {
    margin: 8px;
  }
}
.button-option__spinner {
  text-align: center;
  height: 20px;
}
@media screen and (min-width: 768px) {
  .button-option__spinner {
    height: 28px;
    padding: 4px 0px;
  }
}
.button-option:hover {
  background-color: #DDDDDD;
}
.button-option:active {
  background-color: #DDDDDD;
  box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.25);
}

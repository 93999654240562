.informacion_nivel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 24px;
  gap: 5px;
  background: #EEF2F7;
}
@media screen and (max-width: 768px) {
  .informacion_nivel {
    padding: 10px 16px;
    gap: 5px;
    width: 100%;
    justify-content: space-between;
  }
  .informacion_nivel__logo {
    width: 20px;
    height: 20px;
    margin-right: 2px;
  }
  .informacion_nivel__link {
    font-weight: 400;
    min-width: 100px;
    align-self: flex-start;
  }
}
.informacion_nivel__texto {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #4A5568;
  margin: 0;
  margin-left: 5px;
}
@media screen and (max-width: 768px) {
  .informacion_nivel__texto {
    font-size: 12px;
  }
}

.contenedorInput {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin: 20px 0;
  align-items: flex-start;
}

.textoError {
  color: #B60000;
  min-height: 24px;
  margin-left: 5px;
  font-size: 15px;
  line-height: 20px;
}

.contenedorBotones {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  grid-gap: 16px;
  gap: 16px;
  margin-bottom: 24px;
}

.botonSecundario {
  background-color: #EEEEEE;
  color: #003DA5;
}
.botonSecundario:hover {
  background-color: #F3F3F3;
}

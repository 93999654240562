@charset "UTF-8";
.render-field-nuevo {
  display: block;
  width: 100%;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .render-field-nuevo--expanded {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 1024px) {
  .render-field-nuevo--expanded {
    min-height: 74px;
  }
  .render-field-nuevo--expanded .render-field-nuevo__label-container {
    display: inline-block;
    width: 35%;
    text-align: right;
    padding-right: 16px;
  }
  .render-field-nuevo--expanded .render-field-nuevo__input-container {
    display: inline-block;
    width: 400px;
    border: 1px solid #042F62;
    border-radius: 5px;
  }
  .render-field-nuevo--expanded .render-field-nuevo__helper-text-container {
    margin-left: 35%;
  }
}
.render-field-nuevo .render-field-nuevo__input-div-container {
  position: relative;
}
.render-field-nuevo .render-field-nuevo__input-container input, .render-field-nuevo .render-field-nuevo__input-container select {
  border: 1px solid #042F62;
  border-radius: 5px;
  height: 56px;
}
.render-field-nuevo .render-field-nuevo__input-container__button {
  position: absolute;
  right: 3px;
  top: 3px;
  bottom: 3px;
  border: 0;
  outline: none;
  margin: 0;
  padding: 0 10px;
  z-index: 2;
}
.render-field-nuevo .render-field-nuevo__input-container__button img {
  width: 22px;
  height: 22px;
}
.render-field-nuevo .checkbox-olvide-mi-contraseña-contenedor {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: baseline;
}
.render-field-nuevo .checkbox-contenedor {
  display: flex;
  text-align: center;
}
.render-field-nuevo__foreign-user {
  width: 100%;
}
.render-field-nuevo__label-container {
  display: flex;
  justify-content: space-between;
}
.render-field-nuevo__label-container__label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #042F62;
}
.render-field-nuevo__label-container__link {
  line-height: 24px;
  text-decoration-line: underline;
  color: #003DA5;
}
@media screen and (min-width: 768px) {
  .render-field-nuevo__label-container__link {
    line-height: 28px;
  }
}
@media screen and (min-width: 1024px) {
  .render-field-nuevo__label-container__link {
    line-height: 28px;
  }
}
.render-field-nuevo__label-container__link_container {
  display: flex;
  justify-content: flex-start;
}
@media screen and (min-width: 768px) {
  .render-field-nuevo__label-container--text-field {
    margin-top: 8px;
    vertical-align: top;
  }
}
.render-field-nuevo__help-text, .render-field-nuevo__help-text__warn, .render-field-nuevo__help-text__error, .render-field-nuevo__help-text__info {
  height: 20px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  .render-field-nuevo__help-text, .render-field-nuevo__help-text__warn, .render-field-nuevo__help-text__error, .render-field-nuevo__help-text__info {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .render-field-nuevo__help-text, .render-field-nuevo__help-text__warn, .render-field-nuevo__help-text__error, .render-field-nuevo__help-text__info {
    font-size: 14px;
    line-height: 20px;
  }
}
.render-field-nuevo__help-text__info {
  color: #333333;
}
.render-field-nuevo__help-text__error {
  color: #B60000;
}
.render-field-nuevo__help-text__warn {
  color: #FCC526;
}
.render-field-nuevo__input--error {
  border: 2px solid #B60000;
}
.render-field-nuevo__input--error:focus {
  outline: 0;
}
.render-field-nuevo__helper-text-container {
  min-height: 24px;
  margin: 10px 0px 0px 5px;
  font-size: 16px;
  line-height: 20px;
}
@media screen and (min-width: 1024px) {
  .render-field-nuevo__helper-text-container {
    margin-bottom: auto;
  }
}

.mail_activacion__nro_documento .render-field-nuevo__label-container__label {
  font-weight: 400;
  color: rgb(34, 34, 34);
}
@media screen and (max-width: 768px) {
  .mail_activacion__nro_documento .render-field-nuevo__label-container__label {
    font-size: 14px;
  }
}

.captcha-nuevo {
  max-width: 256px;
  margin: 32px 0 !important;
}
.captcha-nuevo div:first-child {
  outline-color: #DAAA00;
  transform: scale(0.95);
  transform-origin: 0px 0px 0px;
}
@media screen and (min-width: 768px) {
  .captcha-nuevo {
    width: 100%;
  }
  .captcha-nuevo div:first-child {
    transform: scale(1);
    transform-origin: 0px 0px 0px;
  }
}

.checkbox-field-nuevo {
  margin: 8px 0 0;
}
.checkbox-field-nuevo__checkbox {
  display: inline-block;
  height: 16px;
  width: 16px;
  min-width: 16px;
  margin: 0 8px 0 0;
  vertical-align: middle;
  text-align: center;
}

.popup-nuevo {
  position: absolute;
  width: 276px;
  max-width: 276px;
  border: 1px solid #BBBBBB;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  padding: 8px;
  font-size: 14px;
  line-height: 20px;
  z-index: 999;
  -webkit-animation: fadein 0.2s linear forwards;
  animation: fadein 0.2s linear forwards;
  opacity: 0;
  left: calc(100% + 12px);
  top: -42px;
}
.popup-nuevo ::before, .popup-nuevo ::after {
  right: 100%;
  top: 20px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.popup-nuevo ::before {
  border-color: rgba(187, 187, 187, 0);
  border-right-color: #BBBBBB;
  border-width: 12px;
  margin-top: -12px;
}
.popup-nuevo ::after {
  border-color: transparent;
  border-right-color: #ffffff;
  border-width: 10px;
  margin-top: -10px;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.password-field-nuevo__text-ok {
  color: #279E3F;
}
.password-field-nuevo__text-warning {
  color: #FCCE26;
}
.password-field-nuevo__text-error {
  color: #B60000;
}
.password-field-nuevo__bar-base, .password-field-nuevo__bar-error, .password-field-nuevo__bar-ok, .password-field-nuevo__bar-warning {
  height: 8px;
  width: 100%;
  border-radius: 3px;
  background-color: #EEEEEE;
  margin-bottom: 8px;
}
.password-field-nuevo__bar-error, .password-field-nuevo__bar-ok, .password-field-nuevo__bar-warning {
  background-color: #B60000;
  width: 0%;
  -webkit-transition: width 1s; /* Safari */
  transition: width 1s;
}
.password-field-nuevo__bar-warning {
  background-color: #FCCE26;
}
.password-field-nuevo__bar-ok {
  background-color: #279E3F;
}
.password-field-nuevo__popup {
  width: 100%;
  padding: 16px 0;
  z-index: 9;
}
@media screen and (min-width: 1024px) {
  .password-field-nuevo__popup {
    max-width: 276px;
    position: absolute;
    border: 1px solid #BBBBBB;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    padding: 16px;
    z-index: 9;
    left: calc(100% + 12px);
    top: -42px;
  }
  .password-field-nuevo__popup ::before, .password-field-nuevo__popup ::after {
    right: 100%;
    top: 20px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .password-field-nuevo__popup ::before {
    border-color: rgba(187, 187, 187, 0);
    border-right-color: #BBBBBB;
    border-width: 12px;
    margin-top: -12px;
  }
  .password-field-nuevo__popup ::after {
    border-color: transparent;
    border-right-color: #ffffff;
    border-width: 10px;
    margin-top: -10px;
  }
}
.password-field-nuevo__popup__title {
  margin: 0 0 8px 0;
}
.password-field-nuevo__popup__tips {
  color: #666666;
}
.password-field-nuevo__popup__tips__link {
  background-color: #FFFFFF;
  border: 0;
  color: #003DA5;
  margin: 0 0 8px 0;
  padding: 0;
}

.radio-buttton-field-nuevo {
  margin-top: 8px;
}
.radio-buttton-field-nuevo__radio-button {
  display: inline-block;
  min-width: 16px;
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0;
}
.radio-buttton-field-nuevo label {
  display: inline-block;
}

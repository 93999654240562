.instrucciones__desc-secondary {
  color: #666666;
  margin-top: 8px;
}
.instrucciones__divider {
  box-sizing: border-box;
  height: 1px;
  width: 90%;
  border: 1px solid #DDDDDD;
  margin: 32px 0 16px;
}
.instrucciones__tip-container {
  margin-top: 8px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .instrucciones__tip-container {
    margin-top: auto;
  }
}
.instrucciones__tip-container__tip {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .instrucciones__tip-container__tip {
    width: auto;
  }
}
.instrucciones__tip-container__tip-action {
  background: none;
  border: none;
  color: #003DA5;
  cursor: pointer;
  text-align: left;
  width: 100%;
  padding: 0;
  background: none;
}
@media screen and (min-width: 768px) {
  .instrucciones__tip-container__tip-action {
    width: auto;
    margin-left: 8px;
  }
}

.mfa__aumentar_nid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mfa__aumentar_nid__container {
  margin-top: 32px;
}
@media screen and (min-width: 1024px) {
  .mfa__aumentar_nid__container {
    width: 480px;
  }
}
.mfa__aumentar_nid__container__link {
  margin-top: 24px;
  text-align: center;
}
.mfa__aumentar_nid__container__link a {
  color: #003DA5;
  font-size: 12px;
  line-height: 24px;
  text-decoration: underline;
}
@media screen and (min-width: 768px) {
  .mfa__aumentar_nid__container__link a {
    font-size: 16px;
    line-height: 28px;
  }
}
.mfa__aumentar_nid__container__link a:visited {
  color: #003DA5;
}
.mfa__aumentar_nid__container__link a:link {
  text-decoration: underline;
}

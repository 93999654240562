.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.containerTexto {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
@media screen and (min-width: 1024px) {
  .containerTexto {
    width: 100%;
    height: 500px;
    overflow-y: scroll;
  }
  .containerTexto::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  .containerTexto::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #9AA1AE;
    box-shadow: 0 0 1px #666666;
  }
}

.parrafoCondiciones, .parrafoNegrita {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 20px;
  align-self: flex-start;
}

.parrafoNegrita {
  font-weight: bold;
}

.orderedList {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 20px 0 20px 0;
}

.condicion::marker {
  font-weight: normal;
}

.condicion {
  font-size: 18px;
  line-height: 24px;
}
.condicion b {
  font-weight: 600;
}
.condicion p {
  margin: 0;
}

.titulo {
  margin: 0px 0px 40px 0px;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  width: 100%;
}

.checkboxesContenedor {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 50px 0 50px 0;
}

.checkbox {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
}

.texto_verde {
  color: #00632e;
  font-weight: bold;
}

.cajaBotones {
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 8px;
}
@media screen and (min-width: 1024px) {
  .cajaBotones {
    width: 385px;
  }
}

.boton {
  height: max-content;
  width: 250px;
}
@media screen and (min-width: 1024px) {
  .boton {
    width: 395px;
  }
}

.labelDetalle {
  align-self: flex-start;
  text-align: left;
  font-weight: bold;
}
@media screen and (min-width: 1024px) {
  .labelDetalle {
    text-align: right;
    margin-left: 8px;
  }
}

.labelDetalleContainer {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}
@media screen and (min-width: 1024px) {
  .labelDetalleContainer {
    margin-bottom: 0px;
    justify-content: flex-end;
  }
}

.descripcionVideollamada {
  width: 60%;
}

.contenedor {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1024px) {
  .contenedor {
    gap: 20px;
  }
}

.saltoLinea {
  display: none;
}
@media screen and (min-width: 1024px) {
  .saltoLinea {
    display: block;
  }
}

.videollamadaInfo {
  width: 100%;
  display: flex !important;
  flex-direction: column;
  align-self: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #000000;
}
@media screen and (min-width: 768px) {
  .videollamadaInfo {
    font-size: 16px;
  }
}
.videollamadaInfo p {
  width: 300px;
  align-self: baseline;
  margin-top: 0px;
  margin-bottom: 0px;
}
@media screen and (min-width: 768px) {
  .videollamadaInfo p {
    width: 363px;
  }
}

.contenedorVideollamadaBotones {
  display: flex;
  flex-direction: row;
  align-self: baseline;
  width: 140px;
  margin-top: 10px;
}

.botonesVideollamadaPrimario, .botonesVideollamadaSecundario {
  width: 123px;
  height: 32px;
  margin-right: 20px;
  padding: 10px 25px;
  font-weight: 600;
  font-size: 14px;
  line-height: 10px;
}

.botonesVideollamadaPrimario :hover, .botonesVideollamadaSecundario :hover {
  text-decoration: none;
}

.botonesVideollamadaSecundario {
  font-weight: 600;
  border: 1px solid #042F62;
  border-radius: 4px;
  color: #042F62;
  background-color: white;
}

.contenedorPrincipal {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contenedorPrincipalVideollamada {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.contenedorNivelIntermedio {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.contenedorDatosFirmaDigital {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.botonLink {
  padding: 0px;
  padding-bottom: 8px;
}

.infoNivelContenedor {
  display: flex;
  font-size: 14px;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .infoNivelContenedor {
    font-size: 16px;
  }
}

.iconoNivelSeguridad {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.textoNivelSeguridad {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #4A5568;
}

.textoInformativoNivel, .textoInformativoNivelMetodos {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  align-self: flex-start;
  color: #000000;
}
@media screen and (min-width: 1024px) {
  .textoInformativoNivel, .textoInformativoNivelMetodos {
    width: 388px;
    font-size: 16px;
    align-self: center;
  }
}
.textoInformativoNivelMetodos {
  margin-top: 3px;
  align-self: flex-start;
  margin-left: 0;
}

.contenedorLabelDetalle {
  display: flex;
  flex-direction: column;
}

.iconoInfoNivelSeguridad {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.masInformacionDetalle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.infoNivelSeguridad {
  margin-top: 10px;
  color: #000000;
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.textoMultiformatoNiveles {
  margin: 0;
}

.linkFirma {
  padding-left: 0;
}

.linkDisabled {
  pointer-events: none;
}

.contenedorMetodos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
@media screen and (min-width: 1024px) {
  .contenedorMetodos {
    align-items: center;
  }
}

.contenedorNivelActual {
  display: flex;
  flex-direction: column;
}

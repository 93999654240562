.titulo {
  font-weight: 600;
  font-size: 32px;
  line-height: 47px;
  text-align: center;
  position: relative;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 40px 16px;
  margin: 0px;
  align-items: center;
  gap: 40px;
}
.container p {
  margin: 0;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
}

.titulo-caja {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  width: 100%;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  gap: 48px;
  margin: 48px 0px;
}

.form-field {
  display: block;
  width: 100%;
  margin: 16px 0 0 0;
}
.form-field__label {
  color: #042F62;
  font-weight: 600;
  font-size: 16px;
}
.form-field__label--error {
  font-size: 16px;
  font-weight: 600;
  color: #B60000;
}
.form-field__label--required {
  color: #9AA1AE;
}
.form-field__input {
  width: 360px;
  height: 56px;
  border: 1px solid #35435D;
  border-radius: 5px;
  margin-top: 0px;
}
.form-field__input:disabled {
  background: #E6E8EB;
  border: none;
  text-transform: none;
}
.form-field__input:disabled:hover {
  border: none;
}
.form-field__input--error {
  height: 56px;
  border: 1px solid #B60000;
  border-radius: 5px;
  margin-top: 0px;
}
.form-field__input--error:focus {
  outline: 0;
}
.form-field__input--exito {
  height: 56px;
  background: white url("../../assets/icons/valid-icon.svg") right 16px center no-repeat;
  margin-right: 16px;
  border: 1px solid #28A745;
  border-radius: 5px;
}
@media screen and (max-width: 768px) {
  .form-field__input {
    width: 328px;
  }
}
.form-field__input--small {
  width: 170px;
  height: 56px;
  border: 1px solid #35435D;
  border-radius: 5px;
  margin-top: 0px;
  font-size: 16px;
}
.form-field__input--small:disabled {
  background: #E6E8EB;
  border: none;
  text-transform: none;
}
.form-field__input--small:disabled:hover {
  border: none;
}
.form-field__input--small--error {
  height: 56px;
  border: 1px solid #B60000;
  border-radius: 5px;
  margin-top: 0px;
}
.form-field__input--small--error:focus {
  outline: 0;
}
@media screen and (max-width: 768px) {
  .form-field__input--small {
    width: 155px;
  }
}
.form-field__select {
  width: 360px;
  height: 56px;
  border: 1px solid #35435D;
  border-radius: 5px;
  margin-top: 0px;
  font-size: 16px;
  background: white url("../../assets/icons/arrow-select.svg") right center no-repeat;
  -webkit-appearance: none;
  background-position-x: 334px;
}
.form-field__select--error {
  height: 56px;
  border: 1px solid #B60000;
  border-radius: 5px;
  background: white url("../../assets/icons/arrow-select.svg") right center no-repeat;
  -webkit-appearance: none;
  background-position-x: 334px;
}
.form-field__select--error:focus {
  outline: 0;
}
@media screen and (max-width: 768px) {
  .form-field__select {
    width: 328px;
    background-position-x: 294px;
  }
}
.form-field select[disabled] {
  border: 1px solid #35435D;
  background: white;
}
.form-field__select--small {
  padding-right: 32px;
  width: 170px;
  height: 56px;
  border: 1px solid #35435D;
  border-radius: 5px;
  margin-top: 0px;
  margin-right: 20px;
  font-size: 16px;
  background: white url("../../assets/icons/arrow-select.svg") right center no-repeat;
  -webkit-appearance: none;
  background-position-x: 146px;
}
.form-field__select--small--error {
  height: 56px;
  border: 1px solid #B60000;
  border-radius: 5px;
  background: white url("../../assets/icons/arrow-select.svg") right center no-repeat;
  -webkit-appearance: none;
  background-position-x: 146px;
}
.form-field__select--small--error:focus {
  outline: 0;
}
@media screen and (max-width: 768px) {
  .form-field__select--small {
    width: 155px;
    background-position-x: 126px;
  }
}
.form-field__input-password {
  width: 360px;
  height: 56px;
  border: 1px solid #35435D;
  border-radius: 5px;
  margin-top: 0px;
}
.form-field__input-password__container {
  position: relative;
  width: 360px;
}
@media screen and (max-width: 768px) {
  .form-field__input-password__container {
    width: 328px;
  }
}
.form-field__input-password__button {
  position: absolute;
  right: 3px;
  top: 3px;
  bottom: 3px;
  border: 0;
  outline: none;
  margin: 0;
  padding: 0 10px;
  z-index: 2;
}
.form-field__input-password__button img {
  width: 22px;
  height: 22px;
}
@media screen and (max-width: 768px) {
  .form-field__input-password__button {
    right: 6px;
    height: 50px;
  }
}
.form-field__input-password--error {
  height: 56px;
  border: 1px solid #B60000;
  border-radius: 5px;
  margin-top: 0px;
}
.form-field__input-password--error:focus {
  outline: 0;
}
.form-field__help-text, .form-field__help-text__error {
  height: 21px;
}
@media screen and (min-width: 1024px) {
  .form-field__help-text, .form-field__help-text__error {
    font-size: 14px;
    line-height: 21px;
  }
}
.form-field__help-text__error {
  color: #B60000;
}
.form-field__help-text__error--small {
  color: #B60000;
  font-size: 13px;
}
.form-field__help-text__error__div {
  margin-left: 0px;
}
.form-field__help-text__error__div--small {
  margin-left: 13px;
}

.captcha {
  width: 328px;
  margin-bottom: 16px;
  margin-left: 16px;
}
.captcha div:first-child {
  outline-color: #DAAA00;
}
.captcha div:first-child div:first-child {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .captcha {
    margin: 0px;
  }
}

.checkbox-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.checkbox-field__label {
  display: flex;
  align-items: center;
  width: 291px;
}
.checkbox-field__checkbox {
  display: inline;
  height: 16px;
  width: 16px;
  min-width: 16px;
  margin: 0 8px 0 0;
  vertical-align: middle;
  text-align: center;
}
.checkbox-field__container {
  display: flex;
  flex-direction: row;
}
.checkbox-field__input {
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox-field__error {
  color: #B60000;
  margin-left: 22px;
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .checkbox-field__error {
    margin-left: 24px;
    font-size: 12px;
  }
}

.render-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 16px 0 0 0;
}
@media screen and (min-width: 1024px) {
  .render-field--expanded {
    min-height: 74px;
  }
  .render-field--expanded .render-field__label-container {
    display: inline-block;
    width: 35%;
    text-align: right;
    padding-right: 16px;
  }
  .render-field--expanded .render-field__input-container {
    display: inline-block;
    width: 400px;
  }
  .render-field--expanded .render-field__helper-text-container {
    margin-left: 35%;
  }
}
.render-field__label-container__link {
  float: right;
  cursor: pointer;
}
.render-field__help-text, .render-field__help-text__warn, .render-field__help-text__error, .render-field__help-text__info {
  height: 20px;
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  .render-field__help-text, .render-field__help-text__warn, .render-field__help-text__error, .render-field__help-text__info {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .render-field__help-text, .render-field__help-text__warn, .render-field__help-text__error, .render-field__help-text__info {
    font-size: 14px;
    line-height: 20px;
  }
}
.render-field__help-text__info {
  color: #333333;
}
.render-field__help-text__error {
  color: #B60000;
}
.render-field__help-text__warn {
  color: #FCC526;
}
.render-field__input--error {
  border: 2px solid #B60000;
}
.render-field__input--error:focus {
  outline: 0;
}
.render-field__helper-text-container {
  min-height: 24px;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 20px;
}
@media screen and (min-width: 1024px) {
  .render-field__helper-text-container {
    margin-bottom: auto;
  }
}

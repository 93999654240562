.header-external-opt-out {
  display: inline-block;
  vertical-align: middle;
}

.botonesHeader {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}
@media screen and (min-width: 768px) {
  .botonesHeader {
    margin-right: 20px;
    gap: 20px;
  }
}

@import "../../styles/helpers/variables.scss";
@import "../RenderField/styles.scss";
@import "../../styles/helpers/mixins.scss";

.password-field {
	display: flex;
	flex-direction: column;
	&__text-ok {
		color: $success;
	}

	&__text-warning {
		color: $grey-1;
	}

	&__text-error {
	  color: $error;
	}

	&__bar-base {
		height: 8px;
		width: 100%;
		border-radius: $border-radius;
		background-color: $grey-6;
	  margin-bottom: 8px;
	}

	&__bar-error {
		@extend .password-field__bar-base;
	  background-color: $error;
	  width: 0%;
	  -webkit-transition: width 1s; /* Safari */
	  transition: width 1s;
	}

	&__bar-warning {
		@extend .password-field__bar-error;
	  background-color: #FCCE26;
	}

	&__bar-ok {
		@extend .password-field__bar-error;
	  background-color: $success;
	}

	&__popup {
		width: 100%;
	  padding: 16px 0;
		z-index: 9;

		@include desktop {
			max-width: 276px;
			position: absolute;
			border: 1px solid $grey-4;
			border-radius: $border-radius;
			background-color: #FFFFFF;
			box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
		  padding: 16px;
			z-index: 9;
	    left: calc(100% + 12px);
  		top: -42px;

			::before, ::after {
		    right: 100%;
		  	top: 20px;
		  	border: solid transparent;
		  	content: " ";
		  	height: 0;
		  	width: 0;
		  	position: absolute;
		  	pointer-events: none;
		  }

		  ::before {
		    border-color: rgba(187, 187, 187, 0);
		  	border-right-color: #BBBBBB;
		  	border-width: 12px;
		  	margin-top: -12px;
		  }

		  ::after {
		  	border-color: transparent;
		  	border-right-color: #ffffff;
		  	border-width: 10px;
		  	margin-top: -10px;
		  }
		};

		&__title {
			margin: 0 0 8px 0;
		}

		&__tips {
			color: $grey-3;

			&__link {
				background-color: #FFFFFF;
				border: 0;
			  color: $blue;
			  margin: 0 0 8px 0;
				padding: 0;
			}
		}
	}
}

.info-section__item {
  display: block;
}
@media screen and (min-width: 1024px) {
  .info-section__item__left {
    width: 40%;
    float: left;
    text-align: right;
    display: inline-block;
  }
}
@media screen and (min-width: 1024px) {
  .info-section__item__right {
    padding-left: 16px;
    display: inline-block;
  }
}
.info-section__item__right__value {
  font-weight: bold;
}
@media screen and (min-width: 1024px) {
  .info-section__item__right__link {
    margin-top: 8px;
  }
}
.info-section__item__right__button {
  margin-top: 8px;
}
@media screen and (min-width: 1024px) {
  .info-section__item__right__button {
    margin-top: 16px;
  }
}
.info-section__item__right .low {
  font-weight: bold;
  color: #B60000;
}
.info-section__item__right__signature {
  display: block;
}
.info-section__item__right__signature__value {
  font-weight: bold;
}
.info-section__item__right__signature__value__text-green {
  color: green;
}
@media screen and (min-width: 1024px) {
  .info-section__item__right__signature__value {
    display: inline-block;
  }
}
.info-section__item__right__signature__separator {
  display: none;
}
@media screen and (min-width: 1024px) {
  .info-section__item__right__signature__separator {
    display: inline-block;
    vertical-align: text-bottom;
    box-sizing: border-box;
    height: 22px;
    width: 2px;
    border: 2px solid #DDDDDD;
    margin: 0 14px;
  }
}
.info-section__item__right__signature .low {
  font-weight: bold;
  color: #B60000;
}
.info-section__text-red {
  color: #B60000;
}
.info-section__text-mustard {
  color: #BB9F00;
}
.info-section__text-green {
  color: green;
}

.icono {
  height: 18px;
  width: 18px;
  vertical-align: middle;
  margin-left: 7px;
}

.icono-box {
  height: 24px;
  width: 24px;
}

.button-outline, .button-outline--small {
  padding: 8px 24px;
  height: 40px;
  width: fit-content;
  min-width: 98px;
  line-height: 24px;
  border: none;
  color: #3B3B3B;
  background-color: #F3F3F3;
  border-radius: 3px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  font-weight: bold;
  transition: 0.3s all ease-out;
}
.button-outline:hover, .button-outline--small:hover {
  background-color: #DDDDDD;
}
.button-outline:focus, .button-outline--small:focus {
  outline: 2px solid #DAAA00;
  outline-offset: 1px;
}
.button-outline:active, .button-outline--small:active {
  background-color: #DDDDDD;
  box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.25);
}
.button-outline--small {
  padding: 6px 12px;
  height: 28px;
  line-height: 16px;
  font-size: 14px;
}

.button-secondary-outline {
  padding: 8px 24px;
  height: 40px;
  font-size: 16px;
  width: fit-content;
  min-width: 98px;
  line-height: 24px;
  padding-top: 12px;
  border: none;
  background-color: #fff;
  color: #003DA5;
  box-shadow: none;
  font-weight: normal;
}
.button-secondary-outline:hover {
  text-decoration: underline;
}
.button-secondary-outline:focus-visible {
  outline: #DAAA00 solid 2px;
}

.button-secondary-outline-mfa {
  padding: 8px 24px;
  color: #000000;
  border: 1px solid #042F62;
  box-sizing: border-box;
  border-radius: 5px;
}
.button-secondary-outline-mfa:hover {
  text-decoration: underline;
}
.button-secondary-outline-mfa:focus {
  outline: #DAAA00 2px solid;
  outline-offset: 1px;
}

.button-secondary-outline-mfa span {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #042F62;
}

.button-outline:disabled, .button-outline--small:disabled,
.button-outline[disabled],
[disabled].button-outline--small {
  background-color: #F3F3F3;
  color: #BBBBBB;
  pointer-events: none;
}

.button-menu-navbar {
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: right;
  color: #003DA5;
  vertical-align: middle;
}
.button-menu-navbar:link {
  color: #003DA5;
}
.button-menu-navbar:focus {
  text-decoration: none;
  color: #222222;
}
.button-menu-navbar:visited {
  color: #042F62;
}
.button-menu-navbar:hover {
  color: #003DA5;
  text-decoration: none;
}
.button-menu-navbar:focus-visible {
  outline: 2px solid #DAAA00;
  outline-offset: 1px;
}

.middle-line {
  border-top: 1px #BBBBBB solid;
  position: absolute;
  left: 10%;
  width: 80%;
}

.middle-line-hidden {
  display: none;
}

.no-padding {
  padding: 0;
}

.p-l-0 {
  padding-left: 0px;
}

.p-r-0 {
  padding-right: 0px;
}

.m-t-0 {
  margin-top: 0px;
}

.m-t-1 {
  margin-top: 16px;
}

.m-b-16px {
  margin-bottom: 16px;
}

.m-l-16px {
  margin-left: 16px;
}

.block {
  display: block;
}

.max-height {
  height: 100% !important;
}

.text-center {
  text-align: center;
}

input,
textarea,
select {
  display: block;
  width: 100%;
  margin: 4px 0 0 0;
  height: 42px;
  min-width: 120px;
  padding: 8px;
  line-height: 24px;
  background-color: white;
  border: 1px solid #BBBBBB;
  border-radius: 2px;
  outline: none;
}
input:hover,
textarea:hover,
select:hover {
  border: 1px solid #666666;
}
input:focus,
textarea:focus,
select:focus {
  outline: 2px solid #DAAA00;
  outline-offset: 1px;
}
input:disabled,
textarea:disabled,
select:disabled {
  border: 1px dotted #BBBBBB;
}
@media screen and (min-width: 1024px) {
  input,
  textarea,
  select {
    margin: 8px 0 0 0;
    line-height: 28px;
  }
}

input[type=checkbox],
input[type=radio] {
  vertical-align: middle;
}

html {
  height: 100%;
}

#root {
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

.main-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: #F9F9F9;
}

.page-container {
  flex: 1;
}

body {
  margin: 0 !important;
  padding: 0;
  min-height: 100%;
  position: relative;
  font-family: "Open Sans", sans-serif;
  background: #F9F9F9;
  color: #222222;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: 24px;
}
@media screen and (min-width: 768px) {
  body {
    font-size: 16px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1024px) {
  body {
    font-size: 16px;
    line-height: 28px;
  }
}
body .alt {
  font-size: 14px;
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  body .alt {
    font-size: 16px;
    line-height: 24px;
  }
}

h1 {
  margin: 1rem 0;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
}
@media screen and (min-width: 768px) {
  h1 {
    font-size: 40px;
    line-height: 56px;
  }
}
@media screen and (min-width: 1024px) {
  h1 {
    font-size: 40px;
    line-height: 56px;
  }
}

h2 {
  margin: 0;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
  line-height: 28px;
}
@media screen and (min-width: 768px) {
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
}
@media screen and (min-width: 1024px) {
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
}

h3 {
  margin: 16px 0;
  font-weight: 600 !important;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 18px;
}
@media screen and (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1024px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  margin: 16px 0;
  font-weight: 600 !important;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  line-height: 16px;
}
@media screen and (min-width: 768px) {
  h4 {
    font-size: 16px;
    line-height: 18px;
  }
}
@media screen and (min-width: 1024px) {
  h4 {
    font-size: 16px;
    line-height: 18px;
  }
}

.headline {
  font-size: 16px;
  line-height: 28px;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
@media screen and (min-width: 768px) {
  .headline {
    font-size: 21px;
    line-height: 32px;
  }
}
@media screen and (min-width: 1024px) {
  .headline {
    font-size: 21px;
    line-height: 32px;
  }
}

.subheading, .mfa__subheading {
  margin: 8px 0px 0px;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 28px;
}
@media screen and (min-width: 768px) {
  .subheading, .mfa__subheading {
    font-size: 18px;
    line-height: 32px;
    margin: 20px 0px 0px;
  }
}
@media screen and (min-width: 1024px) {
  .subheading, .mfa__subheading {
    font-size: 18px;
    line-height: 32px;
    margin: 20px 0px 0px;
  }
}

.caption {
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  .caption {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .caption {
    font-size: 14px;
    line-height: 20px;
  }
}

a {
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: #003DA5;
}
a:focus {
  text-decoration: underline;
  outline: 2px solid #DAAA00;
  outline-offset: 1px;
}
a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .hidden-mobile {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .hidden-tablet {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none;
  }
}

button {
  padding: 0;
  border: 0;
  background: 0;
}

:export {
  blue: #003DA5;
}

.mfa {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
}
.mfa__title {
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 47px;
  width: 55%;
  align-self: center;
  color: #1A202C;
}
@media screen and (max-width: 768px) {
  .mfa__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    margin-top: 8px !important;
    margin-bottom: 15px !important;
    padding-left: 16px;
    padding-right: 16px;
    color: #031435;
    align-self: center;
    width: 100%;
  }
}
.mfa__login {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mfa__login .mfa__title {
  margin-bottom: 25px;
}
.mfa__login .mfa__subtitle, .mfa__login .mfa__line {
  display: none;
}
.mfa__login .mfa__contenido {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .mfa__login .mfa__contenido {
    width: calc(100% - 32px);
    margin: 0px 16px;
  }
}
.mfa__login__cedula, .mfa__login__contrasena, .mfa__login__verificacion, .mfa__login__correo, .mfa__login__mail_institucional {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mfa__login__cedula .mfa__contenido, .mfa__login__contrasena .mfa__contenido, .mfa__login__verificacion .mfa__contenido, .mfa__login__correo .mfa__contenido, .mfa__login__mail_institucional .mfa__contenido {
  width: 360px;
  align-self: center;
}
@media screen and (max-width: 768px) {
  .mfa__login__cedula .mfa__contenido, .mfa__login__contrasena .mfa__contenido, .mfa__login__verificacion .mfa__contenido, .mfa__login__correo .mfa__contenido, .mfa__login__mail_institucional .mfa__contenido {
    margin: 0px 16px;
    width: calc(100% - 32px);
  }
}
.mfa__login__cedula .mfa__title, .mfa__login__contrasena .mfa__title, .mfa__login__verificacion .mfa__title, .mfa__login__correo .mfa__title, .mfa__login__mail_institucional .mfa__title {
  margin-bottom: 18px;
}
@media screen and (max-width: 768px) {
  .mfa__login__cedula .mfa__title, .mfa__login__contrasena .mfa__title, .mfa__login__verificacion .mfa__title, .mfa__login__correo .mfa__title, .mfa__login__mail_institucional .mfa__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }
}
.mfa__login__cedula .mfa__action-buttons__continuar, .mfa__login__contrasena .mfa__action-buttons__continuar, .mfa__login__verificacion .mfa__action-buttons__continuar, .mfa__login__correo .mfa__action-buttons__continuar, .mfa__login__mail_institucional .mfa__action-buttons__continuar {
  width: 100%;
  background: #042F62;
  border-radius: 5px;
  margin-bottom: 32px;
}
.mfa__login__cedula .mfa__extra_field--title, .mfa__login__contrasena .mfa__extra_field--title, .mfa__login__verificacion .mfa__extra_field--title, .mfa__login__correo .mfa__extra_field--title, .mfa__login__mail_institucional .mfa__extra_field--title {
  color: #042F62;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}
.mfa__login__cedula .mfa__extra_field--value, .mfa__login__contrasena .mfa__extra_field--value, .mfa__login__verificacion .mfa__extra_field--value, .mfa__login__correo .mfa__extra_field--value, .mfa__login__mail_institucional .mfa__extra_field--value {
  padding: 10px;
  background: #E6E8EB;
  border-radius: 5px;
  height: 56px;
  display: flex;
  align-items: center;
}
.mfa__login__cedula .mfa__extra_field--value p, .mfa__login__contrasena .mfa__extra_field--value p, .mfa__login__verificacion .mfa__extra_field--value p, .mfa__login__correo .mfa__extra_field--value p, .mfa__login__mail_institucional .mfa__extra_field--value p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #031435;
  margin: 0px;
}
.mfa__login__cedula .mfa__extra_field .render-field-nuevo__helper-text-container, .mfa__login__contrasena .mfa__extra_field .render-field-nuevo__helper-text-container, .mfa__login__verificacion .mfa__extra_field .render-field-nuevo__helper-text-container, .mfa__login__correo .mfa__extra_field .render-field-nuevo__helper-text-container, .mfa__login__mail_institucional .mfa__extra_field .render-field-nuevo__helper-text-container {
  display: none;
}
.mfa__login__cedula .mfa__totp, .mfa__login__contrasena .mfa__totp, .mfa__login__verificacion .mfa__totp, .mfa__login__correo .mfa__totp, .mfa__login__mail_institucional .mfa__totp {
  align-self: center;
  text-align: center;
}
.mfa__login__contrasena .render-field-nuevo__input-container__button {
  position: absolute;
  right: 3px;
  top: 3px;
  bottom: 3px;
  border: 0;
  outline: none;
  margin: 0;
  padding: 0 10px;
  z-index: 2;
}
.mfa__login__contrasena .render-field-nuevo__input-container__button img {
  width: 22px;
  height: 22px;
}
.mfa__login__verificacion .recibir_por_correo {
  padding: 5px !important;
}
.mfa__login__verificacion .mfa__title {
  margin-bottom: 16px;
}
.mfa__login__verificacion .mfa__totp__informacion {
  margin: 0px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #031435;
}
.mfa__login__verificacion .mfa__totp__donde_encuentro {
  margin: 15px 0px 37px;
  color: #003DA5;
}
.mfa__login__verificacion .mfa-container {
  margin-bottom: 15px !important;
}
.mfa__login__verificacion .mfa-container .render-field-nuevo__helper-text-container {
  margin-bottom: 8px !important;
}
.mfa__login__verificacion .mfa__user_level {
  margin-bottom: 24px;
}
.mfa__login__verificacion .mfa__action-buttons {
  margin-top: 32px;
}
.mfa__login__verificacion .mfa__contenido .mfa__link--card-bottom {
  margin-top: 0px;
  text-decoration: underline;
}
.mfa__login__foreign {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mfa__login__foreign__contenido {
  width: 360px !important;
  align-self: center;
}
@media screen and (max-width: 768px) {
  .mfa__login__foreign__contenido {
    margin: 0px 16px !important;
    width: calc(100% - 32px) !important;
  }
}
.mfa__login__foreign__title {
  color: #1A202C;
  margin-bottom: 2px;
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  line-height: 47px;
  width: 55%;
  align-self: center;
}
@media screen and (max-width: 768px) {
  .mfa__login__foreign__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    width: 100%;
  }
}
.mfa__login__foreign .mfa__action-buttons__foreign {
  margin-top: 12px;
}
.mfa__login__foreign .mfa__action-buttons__foreign__continuar {
  width: 100%;
  background: #042F62;
  border-radius: 5px;
  margin-bottom: 16px;
}
.mfa__ci_card {
  min-height: 344px;
  align-self: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.mfa__ci_card__titulo {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 47px;
  text-align: center;
  color: #1A202C;
  align-self: center;
  margin-bottom: 22px;
  margin-right: 10px;
  margin-left: 10px;
}
.mfa__ci_card__contenido {
  width: 350px;
  align-self: center;
  text-align: center;
}
.mfa__ci_card__contenido__continuar {
  width: 100%;
  background: #042F62;
  border-radius: 5px;
}
.mfa__ae3_methods, .mfa__aumentar_nid {
  margin: 20px 0px 0px;
}
.mfa__ae3_methods .mfa__title, .mfa__aumentar_nid .mfa__title {
  margin-bottom: 18px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .mfa__ae3_methods .mfa__title, .mfa__aumentar_nid .mfa__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }
}
.mfa__ae3_methods .mfa__subheading, .mfa__aumentar_nid .mfa__subheading {
  text-align: center;
}
@media screen and (max-width: 768px) {
  .mfa__ae3_methods .mfa__subheading, .mfa__aumentar_nid .mfa__subheading {
    margin: 0px 16px;
    width: calc(100% - 32px);
  }
}
.mfa__ae3_methods .mfa-container, .mfa__aumentar_nid .mfa-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .mfa__ae3_methods .mfa-container, .mfa__aumentar_nid .mfa-container {
    margin: 0px 16px;
    width: calc(100% - 32px);
  }
}
.mfa__ae3_methods .mfa-container .button-section, .mfa__aumentar_nid .mfa-container .button-section {
  margin: 16px auto 10px;
  width: 100%;
}
.mfa__ae3_methods .mfa-container .button-section .button-secondary-outline-mfa, .mfa__aumentar_nid .mfa-container .button-section .button-secondary-outline-mfa {
  width: 480px;
}
@media screen and (max-width: 768px) {
  .mfa__ae3_methods .mfa-container .button-section .button-secondary-outline-mfa, .mfa__aumentar_nid .mfa-container .button-section .button-secondary-outline-mfa {
    font-size: 14px;
    width: 100%;
  }
}
.mfa__info {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .mfa__info {
    margin: 0px 16px;
  }
}
.mfa__info__line {
  border: 1px solid #E0E0E0;
  width: 480px;
  margin: 10px 0px 32px;
  align-self: center;
}
@media screen and (max-width: 768px) {
  .mfa__info__line {
    width: 100%;
    margin: 0px 0px 16px;
  }
}
.mfa__info__que-es {
  align-self: center;
  text-align: center;
  margin-top: 15px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #003DA5;
  width: max-content;
  padding: 5px;
}
.mfa__info__crear {
  align-self: center;
  width: 360px !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #042F62;
}
@media screen and (max-width: 768px) {
  .mfa__info__crear {
    width: 100% !important;
  }
}
.mfa__subtitles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 480px;
  align-self: center;
}
@media screen and (max-width: 768px) {
  .mfa__subtitles {
    width: 100% !important;
  }
}
.mfa__subtitles__nivel {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 1px;
}
.mfa__subtitles__cual-elijo {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  text-align: center;
  text-decoration-line: underline;
  color: #003DA5;
  margin-bottom: 1px;
  vertical-align: bottom;
  align-self: flex-end;
}
.mfa__idp_section {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .mfa__idp_section {
    margin: 0px 16px;
  }
}
.mfa__subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #4A5568;
}
.mfa__subheading {
  margin: 8px auto auto;
}
@media screen and (min-width: 768px) {
  .mfa__subheading {
    margin: 20px auto auto;
  }
}
.mfa__user_level p {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #4a5568;
}
.mfa__line {
  height: 2px;
  background-color: #e8e8e8;
  margin-bottom: 32px;
  margin-top: 32px;
  margin-left: 12px;
  flex-grow: 1;
}
.mfa-container {
  margin: 24px auto 24px;
}
.mfa-container__img {
  margin-bottom: 32px;
  padding-top: 8px;
}
.mfa-container__img img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.mfa-foreign {
  width: 100%;
}
.mfa__foreign-container {
  display: flex;
  flex-direction: column;
}
.mfa__foreign-container p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #4A5568;
}
.mfa__foreign-container__top-field {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}
@media screen and (min-width: 992px) {
  .mfa__foreign-container__top-field {
    flex-direction: row;
  }
}
.mfa__foreign-container__top-field__title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #042F62;
  text-align: left;
}
@media screen and (min-width: 992px) {
  .mfa__foreign-container__top-field__title {
    padding-right: 10px;
    text-align: left;
  }
}
.mfa__foreign-container__bottom-field {
  display: flex;
  flex-direction: column;
  margin: 12px auto 0px;
  width: 100%;
}
@media screen and (min-width: 992px) {
  .mfa__foreign-container__bottom-field {
    flex-direction: row;
  }
}
.mfa__foreign-container__bottom-field__title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #042F62;
  text-align: left;
}
@media screen and (min-width: 992px) {
  .mfa__foreign-container__bottom-field__title {
    text-align: left;
    padding-right: 10px;
  }
}
.mfa__foreign-container__bottom-field__fields {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.mfa__foreign-container__bottom-field__fields__field-container {
  display: flex;
  flex-direction: column;
  width: 48%;
}
.mfa__email {
  font-size: 12px;
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  .mfa__email {
    font-size: 14px;
    line-height: 24px;
  }
}
.mfa__email--info {
  color: #666666;
  margin-top: 4px;
}
.mfa__email--section {
  margin-top: 24px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .mfa__email--section {
    padding: 0 8px;
    margin-top: 32px;
    width: 100%;
    text-align: center;
  }
}
.mfa__email--section a {
  font-size: 12px;
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  .mfa__email--section a {
    font-size: 14px;
    line-height: 24px;
    margin-left: 8px;
  }
}
.mfa__email--section p {
  margin: 16px 0 0;
}
@media screen and (min-width: 768px) {
  .mfa__email--section p {
    margin-top: 8px;
  }
}
.mfa__link {
  display: inline-block;
  margin-left: 6px;
}
.mfa__link--card-bottom {
  margin-top: 24px;
  width: 100%;
  padding: 0 48px;
  margin-left: 0;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .mfa__link--card-bottom {
    margin-top: 32px;
    padding: 0;
  }
}
.mfa__action-buttons {
  margin-top: 32px;
}
.mfa__select {
  margin-bottom: 12px;
  display: flex !important;
}
.mfa__spin-class {
  position: absolute;
  top: 272px;
  left: 50%;
}
.mfa__small-card--idps {
  padding: 0 16px 0;
}
@media screen and (min-width: 768px) {
  .mfa__small-card--idps {
    padding: 0 40px 0;
  }
}
.mfa__foreign-card {
  max-width: 600px;
}
@media screen and (min-width: 992px) {
  .mfa__foreign-card {
    max-width: 700px;
  }
}
.mfa--registro {
  border-top: 1px solid #eeeeee;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-top: 32px;
  padding: 12px;
  text-align: center;
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  .mfa--registro {
    width: calc(100% + 80px);
    margin-left: -40px;
    padding: 14px;
  }
}
.mfa .slide-left-enter {
  transform: translate(120%);
  opacity: 0.65;
}
.mfa .slide-left-enter.slide-left-enter-active {
  transform: translate(0%);
  opacity: 1;
  transition: transform 250ms ease-in-out 0ms, opacity 0ms ease-in 500ms;
}
.mfa .slide-left-leave {
  transform: translate(0%);
  opacity: 0.65;
}
.mfa .slide-left-leave.slide-left-leave-active {
  transform: translate(-120%);
  opacity: 1;
  transition: transform 250ms ease-in-out 0ms, opacity 0ms ease-out 500ms;
}
.mfa .slide-left-height {
  transition: height 250ms ease-in-out;
}
.mfa .slide-right-enter {
  transform: translate(-120%);
  opacity: 0.65;
}
.mfa .slide-right-enter.slide-right-enter-active {
  transform: translate(0%);
  opacity: 1;
  transition: transform 250ms ease-in-out 0ms, opacity 0ms ease-in 500ms;
}
.mfa .slide-right-leave {
  transform: translate(0%);
  opacity: 0.65;
}
.mfa .slide-right-leave.slide-right-leave-active {
  transform: translate(120%);
  opacity: 1;
  transition: transform 250ms ease-in-out 0ms, opacity 0ms ease-out 500ms;
}
.mfa .slide-right-height {
  transition: height 250ms ease-in-out;
}
.mfa .slide-login-height {
  transition: height 250ms ease-in-out;
}
.mfa .slide-none-height {
  height: auto !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  padding-top: 4px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  padding: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  background-color: #35435D;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 16px;
  width: 200px;
  height: 110px;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 18%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #35435D transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.resolver-error-bloqueado-contenedor {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
  color: #B60000;
  font-size: 14px;
  line-height: 20px;
}
.resolver-error-bloqueado-contenedor a {
  text-decoration: underline;
}

.resolver-error-no-activado-contenedor {
  display: flex;
  flex-direction: column;
  color: #B60000;
  font-size: 14px;
  line-height: 20px;
}
.resolver-error-no-activado-contenedor a {
  text-decoration: underline;
}

.resolver-error-textos {
  margin: 0;
  color: #000000;
  font-size: 14px;
}

.resolver-error-link {
  margin: 0;
  color: #003DA5;
}

.totp-card__title {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 8px;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
@media screen and (min-width: 768px) {
  .totp-card__title {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .totp-card__title {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 24px;
  }
}
.totp-card__desc {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 24px;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
.totp-card__qr {
  width: 100%;
  text-align: center;
  vertical-align: middle;
}
@media screen and (min-width: 768px) {
  .totp-card__qr {
    text-align: left;
  }
}
@media screen and (min-width: 1024px) {
  .totp-card__qr {
    text-align: right;
  }
}
.totp-card-fill {
  display: flex;
  flex-direction: column;
  border: 2px solid #EEEEEE;
  padding: 24px;
  width: 100%;
  margin-bottom: 8px;
}
@media screen and (min-width: 768px) {
  .totp-card-fill {
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .totp-card-fill {
    margin-bottom: 16px;
  }
}
.totp-card-section {
  font-family: "Open Sans", sans-serif;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
.totp-card-section--qr-code {
  width: 128px;
  height: 128px;
  margin: auto;
}
.totp-card-section--text {
  vertical-align: middle;
}
@media screen and (min-width: 768px) {
  .totp-card-section--text {
    padding-top: 14px;
  }
}
.totp-card-section__desc {
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 8px;
  font-family: "Open Sans", sans-serif;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
@media screen and (min-width: 768px) {
  .totp-card-section__desc {
    font-size: 14px;
    line-height: 24px;
    margin-top: 0;
    white-space: nowrap;
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .totp-card-section__desc {
    font-size: 14px;
    line-height: 24px;
    margin-top: 0;
    white-space: nowrap;
    margin-bottom: 16px;
  }
}
.totp-card-section__code {
  font-size: 12.5px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
@media screen and (min-width: 768px) {
  .totp-card-section__code {
    font-size: 14px;
    line-height: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .totp-card-section__code {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }
}

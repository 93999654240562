.landing {
  width: 98%;
  margin: 0 auto;
}
@media screen and (min-width: 992px) {
  .landing {
    width: 80%;
  }
}
@media screen and (min-width: 1024px) {
  .landing {
    max-width: 960px;
  }
}
.landing__page__title {
  text-align: center;
  font-size: 38px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .landing__page__title {
    font-size: 26px;
    margin-bottom: 20px;
  }
}
.landing__page__desc {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 30px;
  text-align: justify;
}
@media screen and (max-width: 768px) {
  .landing__page__desc {
    margin-bottom: 20px;
  }
}
.landing__page__subdesc {
  text-align: justify;
}
.landing__section__button__container {
  width: 100%;
  margin-bottom: 15px;
}
@media screen and (min-width: 1024px) {
  .landing__section__button__container {
    margin-bottom: 0px;
  }
}
.landing__section-small {
  max-width: 960px;
  width: 100%;
}
.landing__section-small__container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 1024px) {
  .landing__section-small__container {
    margin-bottom: 0;
    display: flex;
  }
}
@media screen and (min-width: 1024px) {
  .landing__section-small__container:first-child {
    margin-top: 0;
  }
}
.landing__section-small__card {
  width: 100%;
}
.landing__section-small__card a {
  font-size: 16px;
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  .landing__section-small__card a {
    font-size: 14px;
  }
}
.landing__section-small__icon {
  text-align: center;
  display: none;
  margin-bottom: 15px;
}
.landing__section-small__icon img {
  border-radius: 9999px;
}
@media screen and (min-width: 992px) {
  .landing__section-small__icon {
    display: block;
  }
}
.landing__section-small__icon img {
  width: 90px;
}
.landing__section-small__title {
  color: #000000;
  font-weight: 600;
  margin: 8px auto;
  padding-left: 0px;
  font-size: 28px;
  line-height: 1.1;
  margin-bottom: 20px;
}
.landing__section-small__subtitle {
  color: #000000;
  font-weight: 600;
  margin: 8px auto;
  padding-left: 0px;
  font-size: 20px;
  line-height: 1.5;
}
.landing__section-small__desc {
  font-size: 18px;
  line-height: 1.5em;
  text-align: justify;
}
@media screen and (min-width: 1024px) {
  .landing__section-small__desc {
    font-size: 16px;
    line-height: 28px;
  }
}
@media screen and (max-width: 768px) {
  .landing__section-small__desc {
    font-size: 14px;
  }
}
.landing__section-small__desc div p {
  width: 600px;
}
.landing__section-small__subsection {
  margin: 30px auto;
  text-align: justify;
}
.landing__section-small__subsection__icon {
  margin-bottom: 10px;
}
.landing__section-small__subsection__icon img {
  width: 30px;
  height: 30px;
}
.landing__section-small__subsection__title {
  margin-bottom: 20px;
}
.landing__section-small__subsection__desc {
  text-align: justify;
}
.landing__section-small__subsection__desc a {
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .landing__section-small__subsection__desc a {
    font-size: 14px;
  }
}
.landing__section-small.container > main:nth-child(5) > div > div {
  align-items: stretch;
}
.landing__section-buttons {
  margin-top: 62px;
  margin-bottom: 10px;
}
.landing__section-buttons__title {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.landing__section-buttons__title p {
  margin: 0;
  font-size: 21px;
  text-align: center;
  line-height: 1.125;
}
.landing__section-buttons__buttons {
  display: flex;
  justify-content: center;
}

.landing-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

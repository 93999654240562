@media screen and (min-width: 1024px) {
  .contacto {
    margin: auto;
    max-width: 1304px;
    width: 90%;
  }
}
@media screen and (min-width: 1024px) {
  .contacto--card {
    display: inline-block;
    width: 70%;
    max-width: 70%;
  }
}
.contacto__container {
  margin-top: 24px;
}
.contacto__container__form {
  display: flex;
  text-align: left;
  width: 100%;
}
.contacto__container__form .render-field-nuevo--expanded {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.contacto__container__form .render-field-nuevo__input-container {
  border-width: 0px;
}
.contacto__container__form .render-field-nuevo__input-container input, .contacto__container__form .render-field-nuevo__input-container select, .contacto__container__form .render-field-nuevo__input-container textarea {
  margin-top: 0px;
}
.contacto__container__form .render-field-nuevo__input-container textarea {
  height: 150px;
  resize: none;
}
.contacto__container__form .render-field-nuevo__label-container {
  color: #212221;
  text-align: left;
  width: 100%;
}
.contacto__container__form #formulario-contacto {
  margin: auto;
}
.contacto__container__form .render-field-nuevo__label-container__label {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #212221;
}
@media screen and (min-width: 768px) {
  .contacto__container__form .render-field-nuevo__label-container__label {
    font-size: 16px;
  }
}
.contacto__container__form .render-field-nuevo__helper-text-container {
  margin-left: 0 !important;
}
.contacto__container__form .captchaContainer {
  display: flex;
  align-items: flex-start;
  margin-left: 20px;
}
@media screen and (min-width: 768px) {
  .contacto__container__form .captchaContainer {
    margin-right: 60px;
  }
}
@media screen and (min-width: 1024px) {
  .contacto__container__form .captchaContainer {
    margin-left: 50px;
  }
}
.contacto__container__form .botonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contacto__aside {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.18);
  background-color: #FFFFFF;
  border-radius: 3px;
  width: 90%;
  margin: 16px auto 0;
  padding: 24px;
}
@media screen and (min-width: 768px) {
  .contacto__aside {
    margin: 32px auto 0;
    width: 75%;
  }
}
@media screen and (min-width: 1024px) {
  .contacto__aside {
    display: inline-block;
    width: calc(30% - 24px);
    vertical-align: top;
    margin-top: 32px;
    margin-left: 24px;
  }
}
.contacto__aside__title {
  color: #666666;
}
.contacto__aside__subtitle {
  margin-bottom: 0;
}
.contacto__aside p {
  margin-top: 24px;
}
.contacto__aside__section__title {
  color: #666666;
  font-weight: bold;
}
.contacto__aside__section img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
}
.contacto__aside__section__info {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 64px);
}
.contacto__aside__section:last-of-type {
  margin-bottom: 8px;
}
.contacto__aside a {
  word-wrap: break-word;
}
.contacto__field-popup .popup-nuevo {
  left: 412px;
}

.iframeVideollamada {
  height: 550px;
}
@media screen and (min-width: 768px) {
  .iframeVideollamada {
    height: 700px;
  }
}
@media screen and (min-width: 1024px) {
  .iframeVideollamada {
    height: 500px;
  }
}

.titulo {
  font-size: 26px;
  text-align: center;
  margin: 0px 0px 20px;
  line-height: 36px;
}

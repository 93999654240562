footer {
  display: block;
  width: 100%;
  background-color: #003DA5;
  box-sizing: border-box;
  color: #FFFFFF;
  bottom: 0;
  max-height: 481px;
  margin-top: 64px;
}
@media screen and (min-width: 768px) {
  footer {
    max-height: 392px;
  }
}
@media screen and (min-width: 1024px) {
  footer {
    min-height: 260px;
  }
}
footer .footer-wrapper {
  height: 100%;
  max-width: 1304px;
  padding: 16px;
  line-height: 32px;
  text-align: center;
  width: 100%;
}
@media screen and (min-width: 768px) {
  footer .footer-wrapper {
    margin: auto;
    padding: 0 16px;
    text-align: left;
  }
}
@media screen and (min-width: 992px) {
  footer .footer-wrapper {
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  footer .footer-wrapper {
    width: 100%;
    margin: auto;
    padding: 0 16px;
    text-align: left;
  }
}
footer .footer-wrapper a {
  color: #FFFFFF !important;
}
footer .footer-wrapper__top {
  display: block;
}
@media screen and (min-width: 768px) {
  footer .footer-wrapper__top {
    padding: 30px 0px;
  }
}
@media screen and (min-width: 1024px) {
  footer .footer-wrapper__top {
    padding: 56px 0;
  }
}
footer .footer-wrapper__top__left {
  display: flex;
  font-size: 26px;
  font-weight: bold;
  padding: 16px 0;
  vertical-align: bottom;
  text-align: initial;
}
@media screen and (min-width: 768px) {
  footer .footer-wrapper__top__left {
    display: inline-block;
    font-size: 32px;
    padding: 0 10% 0 0;
    margin-bottom: 4px;
    margin-left: 14px;
    border-right: 1px solid rgba(255, 255, 255, 0.15);
  }
}
@media screen and (min-width: 1024px) {
  footer .footer-wrapper__top__left {
    font-size: 32px;
    padding: 0 20% 0 0;
    margin-bottom: 0px;
    border-right: 1px solid rgba(255, 255, 255, 0.15);
  }
}
@media screen and (max-width: 768px) {
  footer .footer-wrapper__top__left {
    font-size: 20px;
    margin-left: 15px;
  }
}
footer .footer-wrapper__top__right {
  display: flex;
}
@media screen and (min-width: 768px) {
  footer .footer-wrapper__top__right {
    display: inline-block;
    padding-left: 5%;
  }
}
@media screen and (min-width: 1024px) {
  footer .footer-wrapper__top__right {
    display: inline-block;
    padding-left: 40px;
  }
}
footer .footer-wrapper__top__right__col {
  margin-bottom: 8px;
  padding: 0px 16px;
}
@media screen and (min-width: 768px) {
  footer .footer-wrapper__top__right__col {
    padding: 0px 16px;
    display: inline-block;
  }
}
@media screen and (min-width: 1024px) {
  footer .footer-wrapper__top__right__col {
    display: inline-block;
    margin: 0 16px;
  }
}
footer .footer-wrapper__top__right__col a {
  font-size: 16px;
}
@media screen and (min-width: 768px) {
  footer .footer-wrapper__top__right__col a {
    vertical-align: bottom;
  }
}
@media screen and (min-width: 1024px) {
  footer .footer-wrapper__top__right__col a {
    vertical-align: bottom;
  }
}
@media screen and (max-width: 768px) {
  footer .footer-wrapper__top__right__col a {
    font-size: 12px;
  }
}
footer .footer-wrapper__bottom {
  display: block;
  font-size: 12px;
}
@media screen and (min-width: 768px) {
  footer .footer-wrapper__bottom {
    padding: 8px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    text-align: right;
    font-size: 14px;
  }
}
@media screen and (min-width: 1024px) {
  footer .footer-wrapper__bottom {
    padding: 8px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    text-align: right;
    font-size: 14px;
  }
}
footer .footer-wrapper__bottom__right {
  vertical-align: middle;
  font-weight: 500;
  font-size: 14px;
}
@media screen and (min-width: 768px) {
  footer .footer-wrapper__bottom__right {
    display: inline-block;
  }
}
@media screen and (min-width: 1024px) {
  footer .footer-wrapper__bottom__right {
    display: inline-block;
  }
}
footer .footer-wrapper__bottom__right__col {
  margin-bottom: 8px;
}
@media screen and (min-width: 768px) {
  footer .footer-wrapper__bottom__right__col {
    display: inline-block;
    margin: 0 0 0 24px;
  }
}
@media screen and (min-width: 1024px) {
  footer .footer-wrapper__bottom__right__col {
    display: inline-block;
    margin: 0 0 0 24px;
  }
}
@media screen and (max-width: 768px) {
  footer .footer-wrapper__bottom__right__col a {
    font-size: 12px;
  }
}

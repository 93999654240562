// ------------------------------------------------------------------------------------------------------------------
// Font
// ------------------------------------------------------------------------------------------------------------------
$font: 'Open Sans', sans-serif;

// ------------------------------------------------------------------------
// Responsive breakpoints
// ------------------------------------------------------------------------
$desktop-min-width: 1024px;
$small-desktop-min-width: 992px;
$tablet-min-width: 768px;
$render-field-expanded-label-width: 35%;

// ------------------------------------------------------------------------
// Colors
// ------------------------------------------------------------------------
$primary: #0060a3;
$primary-dark: #004270;
$primary-light: #5696FF;
$primary-disabled: #9AA1AE;
$secondary: #FCD649;
$secondary-dark: #FCC526;
$secondary-light: #FFF6DC;
$secondary-button: #daaa00;
$secondary-button-light: #fcce26;
$error: #B60000;
$error-dark: #a31818;
$error-light: #FFE5E5;
$success: #279E3F;
$success-light: #E8FFEC;
$grey-1: #222222;
$grey-2: #333333;
$grey-3: #666666;
$grey-4: #BBBBBB;
$grey-5: #DDDDDD;
$grey-6: #EEEEEE;
$grey-7: #F3F3F3;
$grey-8: #F9F9F9;
$grey-9: #E2E9F1;
$grey-10: #687286;
$grey-11: #9AA1AE;
$grey-12: #4A5568;
$dark-grey: #3B3B3B;
$white: #FFFFFF;
$black: #000000;
$red-1: #EB5757;
$disabled: #E6E8EB;

$transitionDefault: 0.3s all ease-out;

// ------------------------------------------------------------------------
// Colors - gub.uy
// ------------------------------------------------------------------------
$blue: #003DA5;
$dark-blue: #042F62;
$dark-blue-1: #031435;
$light-blue: #80bdff;
$light-blue-2: #007bff40;
$green: #00632e;
$green-2: #e8ffec;
$green-3: #28A745;
$mustard: #BB9F00;
$gold: #DAAA00;
$gold-1: #ffbf47;
$purple: #33236a;
$color-1: #3155a6;
$color-2: #8e97c7;
$color-3: #33236a;
$color-4: #93117e;
$group--color1: #e2e9f1;
$color-5: #ddd;
$color-6: #9aacb3;
$dark-red: #B60000;

// ------------------------------------------------------------------------
// Border radius
// ------------------------------------------------------------------------
$border-radius: 3px;
$border-radius-input: 2px;

// ------------------------------------------------------------------------
// Spacing
// ------------------------------------------------------------------------
$spacing-8: 8px;
$spacing-16: 16px;
$spacing-24: 24px;
$spacing-32: 32px;
$spacing-40: 40px;
$spacing-48: 48px;
$spacing-56: 56px;
$spacing-64: 64px;
$spacing-72: 72px;
$spacing-128: 128px;

// ------------------------------------------------------------------------
// Sizes
// ------------------------------------------------------------------------
$min-width: 860px;

$content-max-width: 960px;
$content-width-phone: 90%;
$content-width-tablet: 75%;
$content-width-desktop: 75%;

// ------------------------------------------------------------------------
// Nav
// ------------------------------------------------------------------------
$nav-max-width: 1280px;
$nav-height: 48px;
$nav-item-padding: 12px 24px;


$header-max-width: 1304px;
$header-height-phone: 47px;
$header-height-desktop: 55px;

// ------------------------------------------------------------------------
// Footer (pp)
// ------------------------------------------------------------------------
$footer-height-phone: 336px;
$footer-height-tablet: 231px;
$footer-height-desktop: 199px;

$footer-width-tablet: 522px;
$footer-width-desktop: $content-max-width;

$footer-line-height: 54px;

$footer-feeback-phone: 145px;
$footer-feeback-tablet: 161px;
$footer-feeback-desktop: 61px;

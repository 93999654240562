.terminos-y-condiciones {
  font-size: 12px;
  font-weight: 400;
}
.terminos-y-condiciones a {
  font-size: 12px;
  font-weight: 400;
}

.container_flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.subtitulo {
  color: #4A5568;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
}

.registro__headline {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #4A5568;
}
@media screen and (max-width: 768px) {
  .registro__headline {
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 21.79px;
    text-align: center;
  }
}
.registro__title__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 774px;
  margin-bottom: 32px;
}
@media screen and (max-width: 768px) {
  .registro__title__container {
    margin: 8px;
    width: 328px;
  }
}
.registro__formulario {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.registro__formulario .divDocumento {
  display: flex;
  flex-direction: row;
}
.registro__formulario .condicionesYBoton {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.registro__formulario .condicionesYBoton .botonRegistro {
  width: 100%;
  margin-top: 16px;
}
.registro__alert {
  margin: 16px auto 0;
  max-width: 960px;
  width: 90%;
  display: block;
}
@media screen and (min-width: 768px) {
  .registro__alert {
    margin: 32px auto 0;
    width: 75%;
  }
}
@media screen and (min-width: 1024px) {
  .registro__alert {
    margin: 32px auto 0;
    width: 960px;
  }
}
.registro__footer {
  float: left;
  margin-top: 50px;
  width: 100%;
}
.registro__footer__divider {
  height: 2px;
  width: 30%;
  background-color: #DDDDDD;
  margin-bottom: 16px;
}
@media screen and (min-width: 1024px) {
  .registro__footer__divider {
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 768px) {
  .registro__footer__divider {
    margin-bottom: 8px;
  }
}
.registro__footer__content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
.registro__footer__content p, .registro__footer__content a {
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .registro__footer__content {
    flex-direction: column;
    align-items: flex-start;
  }
  .registro__footer__content a, .registro__footer__content p {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .registro__footer {
    margin-top: 25px;
  }
}

.seccion-pais__bottom {
  margin-bottom: 32px;
}
@media screen and (max-width: 768px) {
  .seccion-pais__bottom {
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 768px) {
  .seccion-pais__bottom {
    margin-top: 25px;
  }
}
.seccion-pais__top {
  margin-top: 32px;
}
@media screen and (max-width: 768px) {
  .seccion-pais__top {
    margin-top: 24px;
  }
}

.help_text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  color: #000000;
  font-size: 15px;
  line-height: 22px;
}

.field_numero_telefono {
  color: #042F62;
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 32px;
}
.field_numero_telefono_parentesis {
  font-weight: 400;
  color: #9AA1AE;
}

.form_label {
  color: #042F62;
  font-weight: 600;
  font-size: 16px;
}

.contenedor_numero_telefono input {
  width: 328px !important;
  height: 56px;
  border-radius: 4px;
  padding: 17px 0 17px 16px;
  margin-top: 0;
  border-color: #042F62 !important;
  background: #FFFFFF;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #031435;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .contenedor_numero_telefono input {
    width: 360px !important;
  }
}

.mfa-card__title {
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
@media screen and (min-width: 768px) {
  .mfa-card__title {
    height: 36px;
    font-size: 28px;
    line-height: 36px;
  }
}
@media screen and (min-width: 1024px) {
  .mfa-card__title {
    height: 36px;
    font-size: 28px;
    line-height: 36px;
  }
}
.mfa-card__desc {
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 16px 0;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
@media screen and (min-width: 768px) {
  .mfa-card__desc {
    font-size: 18px;
    line-height: 26px;
  }
}
@media screen and (min-width: 1024px) {
  .mfa-card__desc {
    font-size: 18px;
    line-height: 26px;
  }
}
.mfa-card__logo {
  display: block;
  margin: auto;
  padding-bottom: 16px;
}
.mfa-card__line {
  height: 2px;
  width: calc(100% + 32px);
  background-color: #EEEEEE;
  margin: 32px auto 32px -16px;
}
@media screen and (min-width: 768px) {
  .mfa-card__line {
    width: calc(100% + 80px);
    margin-top: 48px;
    margin-left: -40px;
  }
}
@media screen and (min-width: 1024px) {
  .mfa-card__line {
    width: calc(100% + 80px);
    margin-top: 48px;
    margin-left: -40px;
  }
}
.mfa-card-section__title {
  margin: 8px 0;
  font-size: 16px;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .mfa-card-section__title {
    font-size: 20px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1024px) {
  .mfa-card-section__title {
    font-size: 20px;
    line-height: 28px;
  }
}
.mfa-card-section__desc {
  font-size: 14px;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  margin: 8px 0;
}
@media screen and (min-width: 768px) {
  .mfa-card-section__desc {
    font-size: 16px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1024px) {
  .mfa-card-section__desc {
    font-size: 16px;
    line-height: 28px;
  }
}
